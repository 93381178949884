import { format, parse, isBefore } from 'date-fns'
import { playlistPickerItems } from 'pickerItems'
import { Platform, Linking } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'
import SpotifyWebApi from 'spotify-web-api-js'
import { createClassForm } from 'apollo/cache'
import { initialCreateClassForm } from 'initialFormStates'
import { REV_CAT_PRODUCT_CREDITS, TRANSACTION_TYPES, TRANSACTION_RECORD_TYPES, ENVIRONMENT_VARIABLES } from '@constants'
const {
    CREDIT_TRANSACTION,
    INTENT_TRANSACTION,
    SUBSCRIPTION_TRANSACTION,
    APPLE_CONSUMABLE_TRANSACTION,
    APPLE_SUBSCRIPTION_TRANSACTION,
} = TRANSACTION_RECORD_TYPES
const { CLASS_PURCHASED } = TRANSACTION_TYPES
import { specializationPickerItems } from 'pickerItems'
import _ from 'lodash'

const emptyObj = {}
const emptyArr = []

const spotifyAPI = new SpotifyWebApi()
const SPOTIFY_TOKEN =
    'BQDdjziH-a4ZpSBjOYgZCvD1PDTkC_LJ3uCPeIIcDzXOHdOG5ej8qJp7pMwvp4EL5BNv4_tXubO6VWUQOMDiMOKGg5Vx1LTpQWiv_fkjecPHz-J7aZDtGa0WDzVxERg7WEHfz4QWum3dtCWZx1S4ia8EYl6i3WHIn0fQs33w8C2l58W4r0pGO2Y'
spotifyAPI.setAccessToken(SPOTIFY_TOKEN)
export const validateEmail = email => {
    const expression =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i

    return expression.test(String(email).toLowerCase())
}

function insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index)
}

export function truncateText({ text, charCeil = 25 }) {
    if (text?.length > charCeil) {
        const index = text?.indexOf(' ', charCeil)
        if (index !== -1) {
            const ellipsed = text?.substr(0, index) + '...'
            return ellipsed
        } else return text?.substr(0, charCeil) + '...'
    }
    return text ?? ''
}

export const formatToAWSPhone = phoneNumber => {
    let formatted = phoneNumber
    formatted = formatted.slice(2)
    formatted = insert(formatted, 3, '-')
    formatted = insert(formatted, 7, '-')
    return formatted
}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1) || string
}

export function getUniqueArrayItems(array) {
    const unique = [...new Set(array)]
    return unique
}

export function checkIfObjectExistsInArray(array, object) {
    return array.some(item => _.isEqual(item, object))
}

export function mergeUniqueArrayObjects(array1, array2) {
    const merged = [...new Set([...array1, ...array2])]
    return merged
}

export function orderByDateAndTime(array) {
    const sorted =
        _?.orderBy(
            array,
            [
                function (item) {
                    return new Date(item?.date)
                },
                function (item) {
                    return item?.startTime
                },
            ],
            ['asc', 'asc'],
        ) ?? emptyArr
    return sorted
}

export function orderByCreatedAt(array) {
    const sorted = _.sortBy(array, item => new Date(item?.createdAt))?.reverse()
    return sorted
}

export function orderByBooking(array) {
    const sorted = _.sortBy(array, item => item?.classBookings?.items?.length).reverse() ?? emptyArr
    return sorted
}

export function getSelectedClassCategories({ classCategories }) {
    const categories = classCategories != null ? Object?.keys(classCategories) : emptyArr
    const selectedCategories = categories?.filter(key => key !== '__typename' && classCategories?.[key] == true)

    return selectedCategories || emptyArr
}

export function getInstructorSpecializationsString({
    classCategories,
    numSpecializations = 1,
    shouldAddSuffix = false,
}) {
    const specializations = classCategories != null ? Object?.keys(classCategories) : emptyArr
    if (specializations?.length == 0) {
        return shouldAddSuffix ? 'Trainer' : ''
    } else {
        const instructorSpecializations = specializations?.filter(
            key => key !== '__typename' && classCategories?.[key] == true,
        )

        const specializationsString = instructorSpecializations
            ?.slice(0, numSpecializations)
            ?.map(specialization => {
                const value = specializationPickerItems.find(x => x.value == specialization)
                return value?.label
            })
            ?.join(', ')

        return specializationsString == '' && shouldAddSuffix ? 'Trainer' : specializationsString
    }
}

// Grabbed from https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object/55251598
// $roots keeps previous parent properties as they will be added as a prefix for each prop.
// $sep is just a preference if you want to seperate nested paths other than dot.
export const flatten = (obj, roots = [], sep = '.') =>
    Object
        // find props of given object
        .keys(obj)
        // return an object by iterating props
        .reduce(
            (memo, prop) =>
                Object.assign(
                    // create a new object
                    {},
                    // include previously returned object
                    memo,
                    Object.prototype.toString.call(obj[prop]) === '[object Object]'
                        ? // keep working if value is an object
                          flatten(obj[prop], roots.concat([prop]))
                        : // include current prop and value and prefix prop with the roots
                          { [roots.concat([prop]).join(sep)]: obj[prop] },
                ),
            {},
        )
export const roundToNearestFive = x => Math.ceil(x / 5) * 5

export function generateID() {
    return Math.random().toString(8).slice(2)
}

//------------------- Picker Population --------------------

export function createApplicationReviewPickerItems({ applications }) {
    const applicationPickerItems = applications?.map(application => ({
        label: application?.socials?.instagram + ' ' + application?.id,
        value: application?.id,
    }))

    return applicationPickerItems || emptyArr
}

export function getSocialURL({ handle, platform }) {
    switch (platform) {
        case 'instagram':
            return `https://www.instagram.com/${handle}/`
        case 'twitter':
            return `https://www.twitter.com/${handle}`
        case 'facebook':
            return `https://www.facebook.com/${handle}`
        case 'snapchat':
            return `https://www.snapchat.com/add/${handle}`
        case 'linkedin':
            return `https://www.linkedin.com/in/${handle}/`
        default:
            return ''
    }
}

export function openURL(url) {
    if (Platform.OS == 'web') {
        window.open(url, '_blank')
    } else {
        Linking.openURL(url)
    }
}
export function getDefaultBanners() {
    const defaultBanners = [
        require('assets/default_banners/default_0.png'),
        require('assets/default_banners/default_1.png'),
        require('assets/default_banners/default_2.png'),
        require('assets/default_banners/default_3.png'),
    ]
    return defaultBanners
}

export function isPlaylistDefault(url) {
    return playlistPickerItems.filter(item => item.value === url).length
}

//https://open.spotify.com/playlist/37i9dQZF1DXcBWIGoYBM5M?si=eb456589cf654821 sample playlist url
export function parsePlaylistID(url) {
    const idIdentifier = 'playlist/'
    const idStartIndex = url.lastIndexOf(idIdentifier) + idIdentifier.length
    const idEndIndex = url.indexOf('?')
    const idLength = idEndIndex - idStartIndex
    const playlistID = url.substr(idStartIndex, idLength)
    return playlistID
}

export async function getSpotifyPlaylistImage(id) {
    try {
        const response = await spotifyAPI.getPlaylistCoverImage(id)
        const imageURL = response[0]?.url
        return imageURL
    } catch (error) {
        console.log('error fetching playlist image: ', error)
        return ''
    }
}

//------------------- Time Helpers --------------------

export function getTimeSinceCreatedAt(createdAt) {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const msPerMonth = msPerDay * 30

    const now = new Date()
    const then = new Date(createdAt)
    const elapsed = now - then

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago'
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago'
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago'
    } else if (elapsed < msPerMonth) {
        return format(then, 'MMMM d yyyy')
    }
}

export function getCurrentHour({ shouldAddPrefix = false } = {}) {
    const currentHour = new Date().getHours()
    if (currentHour < 10 && shouldAddPrefix) {
        return '0' + parseInt(currentHour)
    } else {
        return currentHour
    }
}

export function getCurrentMinutes() {
    return new Date().getMinutes()
}

export function isClassTimeWithinXHours({ startTime, numHours = 1 }) {
    const classHour = parseInt(startTime?.substring(0, 2))
    const classMinutes = parseInt(startTime?.substring(3, 5))
    const currentHour = getCurrentHour()
    const currentMinutes = getCurrentMinutes()
    const hourDiff = Math.abs(classHour - currentHour)
    const minutesDiff = hourDiff <= numHours ? Math.abs(currentMinutes - classMinutes) : classMinutes - currentMinutes
    const validHour = hourDiff <= numHours
    const validMinutes = hourDiff == 0 ? true : minutesDiff >= 0
    return validHour && validMinutes
}

export function isClassTimeWithinTenMinutes(startTime) {
    const classHour = parseInt(startTime?.substring(0, 2))
    const classMinutes = parseInt(startTime?.substring(3, 5))
    const currentHour = getCurrentHour()
    const currentMinutes = getCurrentMinutes()
    const hourDiff = Math.abs(classHour - currentHour)
    const classIsPreviousHour = hourDiff == 1 && classHour < currentHour
    const classIsCurrentHour = hourDiff == 0
    const adjustedClassMinutes = classMinutes + 60
    const minutesDiff = classIsCurrentHour ? classMinutes - currentMinutes : adjustedClassMinutes - currentMinutes

    return minutesDiff <= 10 || classIsPreviousHour
}

export function toTimeString(time) {
    const sign = Math.sign(time)
    const absTime = Math.abs(time)
    const minutes = Math.floor(absTime / 60)
    const seconds = absTime - minutes * 60
    const timeString =
        minutes && seconds
            ? sign * minutes + ' min ' + seconds + ' sec'
            : minutes && !seconds
            ? sign * minutes + ' min'
            : sign * seconds + ' sec'
    return timeString
}

export function calculateClassTimeRemaining({ timeType, duration, timeRemaining, addTime = false }) {
    const delta = timeType == 'min' ? duration * 60 : duration
    const newTime = addTime
        ? parseInt(timeRemaining, 10) + parseInt(delta, 10)
        : parseInt(timeRemaining, 10) - parseInt(delta, 10)
    return newTime
}

export function toStandardTime(militaryTime) {
    try {
        const time = militaryTime.split(':') // convert to array

        // fetch
        const hours = Number(time[0])
        const minutes = Number(time[1])
        const seconds = Number(time[2])

        // calculate
        let timeValue

        if (hours > 0 && hours <= 12) {
            timeValue = '' + hours
        } else if (hours > 12) {
            timeValue = '' + (hours - 12)
        } else if (hours == 0) {
            timeValue = '12'
        }

        timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes // get minutes
        timeValue += hours >= 12 ? ' pm' : ' am' // get AM/PM

        return timeValue
    } catch (error) {
        return ''
    }
}
export function secToMinString(seconds) {
    return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
}

//------------------- Date Helpers --------------------
export function isDateToday(date) {
    try {
        const utcDate = new Date(date)?.toISOString()?.substr(0, 10)
        const today = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')
        return utcDate === today
    } catch (error) {
        __DEV__ && console.log('error : ', error)
    }
}

export function isDateThisMonth(date) {
    try {
        const dateMonth = new Date(date)?.toISOString()?.substr(5, 2)
        const currentMonth = new Date()?.toISOString()?.substr(5, 2)
        return dateMonth === currentMonth
    } catch (error) {
        __DEV__ && console.log('error : ', error)
    }
}

export function isDateThisWeek(date) {
    try {
        const isoDate = new Date(date)?.toISOString()
        const todayObj = new Date()
        const todayDate = todayObj?.getDate()
        const todayDay = todayObj?.getDay()

        // get first date of week
        const firstDayOfWeek = new Date(todayObj?.setDate(todayDate - todayDay))

        // get last date of week
        const lastDayOfWeek = new Date(firstDayOfWeek)
        lastDayOfWeek.setDate(lastDayOfWeek?.getDate() + 6)

        // if date is equal or within the first and last dates of the week
        return isoDate >= firstDayOfWeek?.toISOString() && isoDate <= lastDayOfWeek?.toISOString()
    } catch (error) {
        __DEV__ && console.log('error : ', error)
    }
}

function getDateObjFromISOString(date) {
    try {
        const year = date?.substr(0, 4)
        const month = date?.substr(5, 2) - 1
        const day = date?.substr(8, 2)

        return new Date(year, month, day)
    } catch (error) {
        __DEV__ && console.log('error : ', error)
    }
}

export function getDateTimeObj(date, startTime, timezoneHourOffset) {
    try {
        const year = date?.substr(0, 4)
        const month = parseInt(date?.substr(5, 2)) - 1 //JS date objects are zero indexed for month
        const day = date?.substr(8, 2)
        const hours = parseInt(startTime?.substring(0, 2)) + timezoneHourOffset
        const minutes = parseInt(startTime?.substring(3, 5))
        const dateObj = new Date(year, month, day, hours, minutes)

        return dateObj
    } catch (error) {
        __DEV__ && console.log('error : ', error)
    }
}

export function convertDateToUTC(date) {
    const timezoneHourOffset = date.getTimezoneOffset() / 60
    const utc = new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getUTCHours() + timezoneHourOffset,
            date.getMinutes(),
            date.getSeconds(),
        ),
    )

    return utc
}

export function getTimeUntilClassStart(date, startTime) {
    try {
        const now = new Date()
        const timezoneHourOffset = now.getTimezoneOffset() / 60
        const classDateTime = getDateTimeObj(date, startTime, timezoneHourOffset)
        const nowUTC = convertDateToUTC(now)
        const msDiff = classDateTime - nowUTC
        const msInDay = 1000 * 60 * 60 * 24
        const daysDiff = msDiff / msInDay
        const hoursDiff = daysDiff * 24
        const minutesDiff = hoursDiff * 60
        const diff = { daysDiff, hoursDiff, minutesDiff }

        return diff
    } catch (error) {
        __DEV__ && console.log('error : ', error)
        return emptyObj
    }
}

export function isClassDateTimeValid(classDate, startTime) {
    if (!classDate && !startTime) {
        return false
    } else {
        try {
            const currentHour = getCurrentHour()
            const currentMinutes = getCurrentMinutes()
            const classMinutes = parseInt(startTime?.substring(3, 5))
            const classHour = parseInt(startTime?.substr(0, 2))
            const isClassToday = isDateToday(classDate)
            const classDateObj = getDateObjFromISOString(new Date(classDate)?.toISOString())
            const todayDateObj = getDateObjFromISOString(new Date()?.toISOString())
            const isDateBeforeToday = isBefore(classDateObj, todayDateObj)

            if (isClassToday) {
                const isCurrentHour = currentHour == classHour
                const isValid = !isCurrentHour || (isCurrentHour && classMinutes > currentMinutes)
                return { isValid, isInvalidTime: !isValid }
            } else {
                const isValid = !isDateBeforeToday
                return { isValid: !isDateBeforeToday, isInvalidDate: !isValid }
            }
        } catch (error) {
            __DEV__ && console.log('error : ', error)
        }
    }
}

export function getTodaysDateInAWSFormat() {
    const today = new Date()
    const todayAWS = [
        today?.getFullYear(),
        ('0' + (today.getMonth() + 1))?.slice(-2),
        ('0' + today.getDate())?.slice(-2),
    ].join('-')

    return todayAWS
}

export function formatDate(dateString, formatString = 'EEEE MMMM do') {
    try {
        return format(parse(dateString, 'yyyy-MM-dd', new Date()), formatString)
    } catch (error) {
        return 'Invalid date'
    }
}

export function formatHourAMPM(hours) {
    const ampm = hours >= 12 ? ' pm' : ' am'
    hours = hours % 12
    hours = hours ? hours : 12
    const strTime = hours + ampm
    return strTime
}
export function getCanStartClass({ date, startTime, isInstructor = false }) {
    const isClassToday = date ? isDateToday(date) : false
    const isStartTimeWithinHour = isClassTimeWithinXHours({ startTime })
    return isInstructor ? isClassToday && isStartTimeWithinHour : isClassToday
}

export function copyClassLinkToClipboard({ id }) {
    const { APP_VARIANT = '' } = ENVIRONMENT_VARIABLES
    const isDev = APP_VARIANT == 'DEVELOPMENT'
    const isStaging = APP_VARIANT == 'STAGING'
    const deploymentBranch = isDev ? 'dev' : isStaging ? 'staging' : 'prod'
    const shareClassLink =
        isDev || isStaging
            ? `https://${deploymentBranch}--homebodylivefitness.netlify.app/app/explore/details?classID=${id}`
            : `https://homebodylivefitness.com/app/explore/details?classID=${id}`
    Clipboard?.setString(shareClassLink)
}

//------------------- Apollo Helpers --------------------
export function removeDeletedRecords(items) {
    return items?.filter(item => !item?._deleted) ?? []
}

export function isFavoriteInstructor({ instructorID, favorites }) {
    const livingFavorites = removeDeletedRecords(favorites?.items)
    const isFavorite = livingFavorites?.filter(favorite => favorite?.instructorID == instructorID)

    return isFavorite?.length ? isFavorite[0] : false
}

export function populateCreateClassForm(classObj) {
    const validFields = Object.keys(initialCreateClassForm)
    const formData = Object.keys(classObj)
        .filter(key => validFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = classObj[key]
            return obj
        }, {})
    createClassForm({ ...formData })
    __DEV__ && console.log('createClassForm: ', createClassForm())
}

export function hasUploadedFile({ files, requiredFileType }) {
    const livingFiles = removeDeletedRecords(files?.items)
    const containsFileType = livingFiles?.filter(file => file?.fileType == requiredFileType && file?.name)

    return containsFileType?.length > 0 ?? false
}

export function getInstructorRatingAverage(reviews) {
    if (!reviews || reviews?.length === 0) return 0
    const avg = reviews?.reduce((a, v) => (a = a + v.rating), 0) / reviews?.length
    const avgString = avg.toFixed(1)
    if (avgString.charAt(2) !== '0') {
        return avgString
    } else {
        return avgString.charAt(0)
    }
}

//------------------- Stripe Helpers --------------------
export function getPriceString(amount) {
    if (!amount || amount === 0) return '$0.00'
    const decimal = amount / 100
    return `$${decimal?.toFixed(2)}` ?? '$0.00'
}

export function getTransactionDate(epochTime) {
    try {
        const time = new Date(epochTime * 1000)
        const date = time?.toISOString()
        const year = date?.substr(0, 4)
        const month = parseInt(date?.substr(5, 2))
        const day = date?.substr(8, 2)
        const longMonth = time?.toLocaleString('en-us', { month: 'long' })
        const dateObj = { year, month, day, longMonth }

        return dateObj
    } catch (error) {
        __DEV__ && console.log('error : ', error)
        return emptyObj
    }
}

export function normalizeTransactionData({ transactions = emptyArr, type, availableAppleProducts, paymentMethods }) {
    if (transactions?.length === 0) {
        return emptyArr
    }
    let transactionsToRender
    switch (type) {
        case CREDIT_TRANSACTION:
            transactionsToRender = transactions?.map(
                ({ credits, createdAt, type: creditTransactionType, associatedClass }) => {
                    const { name = '' } = associatedClass ?? emptyObj
                    const created = Math.floor(new Date(createdAt) / 1000)
                    const date = getTransactionDate(created)
                    const transactionType = 'credit_transaction'
                    const dateString = `${date?.month}/${date?.day}/${date?.year}`
                    const title = `${name ? name : 'Class not found'}`
                    const detail = 'credit balance'
                    const price = `${creditTransactionType === CLASS_PURCHASED ? '-' : '+'} ${credits} credits`
                    return {
                        created,
                        dateString,
                        title,
                        detail,
                        price,
                        credits,
                        transactionType,
                    }
                },
            )
            break

        case INTENT_TRANSACTION:
            transactionsToRender = transactions?.map(
                ({
                    object: transactionType,
                    invoice,
                    created,
                    metadata: { credits: metadataCredits } = emptyObj,
                    payment_method,
                    amount_received,
                }) => {
                    const lastFour = paymentMethods?.get(payment_method)
                    const hasInvoice = invoice !== null
                    const date = getTransactionDate(created)
                    const dateString = `${date?.month}/${date?.day}/${date?.year}`
                    const credits = !hasInvoice ? metadataCredits : ''
                    const title = !hasInvoice ? 'Thank you for your purchase!' : `${date?.longMonth} Subscription`
                    const detail = lastFour ? `card ending in ${lastFour}` : 'card not found on file'
                    const price = getPriceString(amount_received)
                    return {
                        credits,
                        title,
                        detail,
                        price,
                        created,
                        dateString,
                        transactionType,
                    }
                },
            )
            break

        case SUBSCRIPTION_TRANSACTION:
            transactionsToRender = transactions?.map(
                ({ object: transactionType, metadata: { credits: metadataCredits } = emptyObj, created }) => {
                    const date = getTransactionDate(created)
                    const dateString = `${date?.month}/${date?.day}/${date?.year}`
                    const title = 'Subscription Started'
                    const credits = metadataCredits
                    const detail = 'monthly credits'
                    const price = `${credits} credits`
                    return {
                        credits,
                        title,
                        detail,
                        price,
                        created,
                        dateString,
                        transactionType,
                    }
                },
            )
            break

        case APPLE_CONSUMABLE_TRANSACTION:
            transactionsToRender = transactions?.flatMap(({ productId, purchaseDateMillis }) => {
                const productInfo =
                    availableAppleProducts?.filter(({ identifier }) => identifier === productId)?.[0] || emptyObj
                const { price_string: price = '' } = productInfo
                const id = purchaseDateMillis
                const title = 'Thank you for your purchase!'
                const created = purchaseDateMillis / 1000
                const credits = REV_CAT_PRODUCT_CREDITS[productId]
                const date = getTransactionDate(created)
                const dateString = `${date?.month}/${date?.day}/${date?.year}`
                const detail = 'App store purchase'
                const purchaseObj = {
                    id,
                    title,
                    credits,
                    created,
                    detail,
                    price,
                    dateString,
                }
                return credits ? purchaseObj : []
            })
            break

        case APPLE_SUBSCRIPTION_TRANSACTION:
            transactionsToRender = Object.keys(transactions)?.flatMap(productID => {
                const isSubscription = productID?.includes('sub')
                const purchaseDateMillis = transactions?.[`${productID}`]
                const created = purchaseDateMillis / 1000
                const credits = REV_CAT_PRODUCT_CREDITS[productID]
                const date = getTransactionDate(created)
                const dateString = `${date?.month}/${date?.day}/${date?.year}`
                const title = 'Apple Subscription Started'
                const detail = 'monthly credits'
                const price = `${credits} credits`
                const subscriptionObj = {
                    title,
                    detail,
                    price,
                    productID,
                    purchaseDateMillis,
                    created,
                    credits,
                    dateString,
                }
                return isSubscription && credits ? subscriptionObj : []
            })
            break

        default:
            break
    }

    return transactionsToRender || emptyArr
}

export function convertBytesToMB(bytes) {
    return (bytes / 1024 / 1024)?.toFixed(2)
}
