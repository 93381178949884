import React from 'react'
import { View, Modal, StyleSheet, TouchableOpacity } from 'react-native'
import colors from 'colors'
import { Inter700 } from 'typography'
import styled from 'styled-components/native'
import { ContinueButton } from 'components/ContinueButton'
import FormButtons from 'components/FormButtons'
import VectorIcon from 'components/VectorIcon'

const headerText = 'are you sure you want to cancel your class?'
const modalDetails =
    'This can not be undone. Once you cancel your class, all of your clients who have booked into your class will be refunded.'

export const CancelClassModal = ({
    visible,
    firstButtonOnPress,
    secondButtonOnPress,
    actionLoading,
    cancelClassStatus,
}) => {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            style={{
                position: 'absolute',
                top: '25%',
                left: '10%',
                borderColor: 'transparent',
            }}
        >
            <View style={{ flex: 1, paddingTop: '15%' }}>
                <ModalContainer>
                    <ExitButtonContainer onPress={firstButtonOnPress}>
                        <VectorIcon family="MaterialIcons" name="close" size={15} color={colors.black} />
                    </ExitButtonContainer>
                    <ModalHeader>{headerText}</ModalHeader>
                    <ModalDetails>{modalDetails}</ModalDetails>
                    <CancelButton
                        nonHover
                        text="Cancel"
                        actionLoading={actionLoading}
                        apolloActionStatus={cancelClassStatus}
                        onPress={secondButtonOnPress}
                        disabled={actionLoading}
                        color={colors.cancelClassRed}
                    />
                </ModalContainer>
            </View>
        </Modal>
    )
}

const ModalHeader = styled(Inter700)`
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: ${colors.homebodyTurquoise};
    padding-top: 76px;
`
const ModalDetails = styled(ModalHeader)`
    font-size: 15px;
    color: black;
    padding-top: 26px;
    line-height: 35px;
`
const ExitButtonContainer = styled(TouchableOpacity)`
    width: ${props => (props.theme.isMobile ? '100%' : '36px')};
    height: ${props => (props.theme.isMobile ? '100%' : '36px')};
    border-radius: ${props => (props.theme.isMobile ? '100%' : '36px')};
    align-self: flex-end;
    justify-content: center;
    color: ${colors.white};
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
const CancelButton = styled(ContinueButton)`
    width: 122px;
    height: 48px;
    align-self: center;
    margin-top: 12%;
`
const ModalContainer = styled(View)`
    align-self: center;
    width: 735px;
    height: 480px;
    margin: 0px;
    border-radius: 46.61px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    padding-top: 54px;
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 48px;
`

export default CancelClassModal
