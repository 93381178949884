import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
// Selectors
import { resetCreateClassNav } from 'navigationActions'
import { orderByDateAndTime, removeDeletedRecords } from 'helpers'
import { getCurrentUser, getPublishedClassesByInstructorID } from 'selectors'
import { PUBLISHED_CLASS_DETAILS, INSTRUCTOR_DRAWER_INDICES } from 'screenNames'
import { FooterBar } from 'commons/components/FooterBar'
const emptyObj = {}
const emptyArr = []

const PublishedClasses = () => {
    const route = useRoute()
    const { params = {} } = route
    const isFocused = useIsFocused()
    const { resetFormState = false, parentNav = emptyObj, wasInstructorInStreamRoom = false } = params
    const navigation = useNavigation()
    const { id: instructorID } = getCurrentUser()
    const [publishedClasses, setPublishedClasses] = useState(emptyArr)
    const [publishedClassesToken, setPublishedClassesToken] = useState(null)
    const {
        classes: publishedClassesResult,
        loading: classesLoading,
        nextToken: initialPublishedClassesToken,
        fetchMore: fetchMorePublishedClasses,
        refetch: refetchPublishedClasses,
    } = getPublishedClassesByInstructorID({
        instructorID,
        isInstructorView: true,
        onCompleted: () => {
            if (publishedClasses?.length === 0 && !publishedClassesToken) {
                setPublishedClassesToken(initialPublishedClassesToken)
                setPublishedClasses(publishedClassesResult)
            }
        },
    })

    const goToPublishedClassDetails = ({ classID }) =>
        navigation.navigate(PUBLISHED_CLASS_DETAILS, { classID, initial: false, wasInstructorInStreamRoom })

    async function handleFetchMorePublishedClasses() {
        if (publishedClassesToken) {
            const fetchMoreResult = await fetchMorePublishedClasses({ variables: { nextToken: publishedClassesToken } })
            const {
                data: {
                    listClasses: { items: classes = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const moreClasses = removeDeletedRecords(classes)
            const allPublishedClasses =
                publishedClasses?.length > 0 ? [...publishedClasses, ...moreClasses] : [...moreClasses]
            const orderedClasses = orderByDateAndTime(allPublishedClasses)
            setPublishedClasses(orderedClasses)
            setPublishedClassesToken(token)
        }
    }

    useEffect(() => {
        if (resetFormState) {
            resetCreateClassNav({
                navigation: parentNav,
                currentRouteIndex: INSTRUCTOR_DRAWER_INDICES.PUBLISHED_CLASSES_NAVIGATOR,
            })
            setPublishedClasses(emptyArr)
            setPublishedClassesToken(null)
            refetchPublishedClasses()
        }
    }, [params])

    useEffect(() => {
        if (publishedClassesToken !== null) {
            handleFetchMorePublishedClasses()
        }
    }, [publishedClassesToken])

    useEffect(() => {
        if (refetchPublishedClasses && isFocused) {
            if (wasInstructorInStreamRoom) {
                setPublishedClasses(emptyArr)
                setPublishedClassesToken(null)
            }
            refetchPublishedClasses()
        }
    }, [isFocused])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <ClassesFlatList
                    isInstructor
                    hideNoClassesText
                    onCardPress={goToPublishedClassDetails}
                    classesLoading={false}
                    data={publishedClasses}
                    headingText="My Published Classes"
                    subHeadingText="Published Classes"
                    showCreateWhenEmpty
                />
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

export default PublishedClasses
