/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchClassBookings = /* GraphQL */ `
  query SearchClassBookings(
    $filter: SearchableClassBookingFilterInput
    $sort: [SearchableClassBookingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClassBookingAggregationInput]
  ) {
    searchClassBookings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        classID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchableNotificationFilterInput
    $sort: [SearchableNotificationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNotificationAggregationInput]
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        createdAt
        pushToken
        content {
          title
          body
          data
        }
        type
        isNew
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchClasses = /* GraphQL */ `
  query SearchClasses(
    $filter: SearchableClassFilterInput
    $sort: [SearchableClassSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClassAggregationInput]
  ) {
    searchClasses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      username
      firstName
      lastName
      phoneNumber
      avatar {
        bucket
        region
        key
      }
      customerID
      accountID
      credits
      files {
        items {
          id
          name
          ownerName
          trainerApplicationID
          userID
          fileType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      application {
        id
        firstName
        lastName
        username
        isInfluencer
        isTrainer
        isOwner
        submitted
        location {
          city
          state
          zip
        }
        socials {
          instagram
          twitter
          facebook
          snapchat
          linkedin
        }
        gymStudioName
        website
        specializations {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        userID
        lastIndex
        gymStudioAddress {
          streetAddress
          city
          state
          zip
          country
        }
        files {
          nextToken
          startedAt
        }
        approved
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      cognitoUserID
      classes {
        items {
          id
          userID
          name
          description
          date
          categoryFilter
          duration
          equipment
          classStatus
          classStatusString
          price
          startTime
          defaultBannerIndex
          playlist
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      profile {
        firstName
        lastName
        bio
        website
        socials {
          instagram
          twitter
          facebook
          snapchat
          linkedin
        }
        location {
          city
          state
          zip
        }
        specializations {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
      }
      userType
      userTypeString
      classCategories {
        barre
        bound
        crossfit
        core
        circuitTraining
        dance
        HIIT
        kickBoxing
        meditation
        pilates
        power
        pregnancy
        sculpt
        strength
        stretch
        yoga
        zumba
        cardio
        endurance
        jump
        foamRoll
        run
        yogaSculpt
        restorative
        recovery
        strengthAndConditioning
        flow
        boxing
        celebrity
        weightLoss
        bodyWeight
        stability
        mobility
        functionalTraining
        glutes
        legs
        arms
        performanceTraining
        flexibility
        spiritualWellness
        wellness
        breathWork
        beginner
        advanced
        kettleBell
        tabata
        bodyBuilding
        athleteTraining
        cycling
        plusSize
        other
      }
      categoryFilter
      classBookings {
        items {
          id
          classID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          review
          rating
          classID
          userID
          instructorID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites {
        items {
          id
          userID
          instructorID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          userID
          createdAt
          pushToken
          type
          isNew
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transactions {
        items {
          id
          credits
          classID
          finalCreditBalance
          initialCreditBalance
          type
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      pushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      credits
      classID
      finalCreditBalance
      initialCreditBalance
      type
      userID
      associatedClass {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        credits
        classID
        finalCreditBalance
        initialCreditBalance
        type
        userID
        associatedClass {
          id
          userID
          name
          description
          date
          categoryFilter
          duration
          equipment
          classStatus
          classStatusString
          price
          startTime
          defaultBannerIndex
          playlist
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        credits
        classID
        finalCreditBalance
        initialCreditBalance
        type
        userID
        associatedClass {
          id
          userID
          name
          description
          date
          categoryFilter
          duration
          equipment
          classStatus
          classStatusString
          price
          startTime
          defaultBannerIndex
          playlist
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      review
      rating
      classID
      userID
      instructorID
      user {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        review
        rating
        classID
        userID
        instructorID
        user {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        review
        rating
        classID
        userID
        instructorID
        user {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClassBooking = /* GraphQL */ `
  query GetClassBooking($id: ID!) {
    getClassBooking(id: $id) {
      id
      classID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClassBookings = /* GraphQL */ `
  query ListClassBookings(
    $filter: ModelClassBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClassBookings = /* GraphQL */ `
  query SyncClassBookings(
    $filter: ModelClassBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        classID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      createdAt
      pushToken
      content {
        title
        body
        data
      }
      type
      isNew
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        createdAt
        pushToken
        content {
          title
          body
          data
        }
        type
        isNew
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        createdAt
        pushToken
        content {
          title
          body
          data
        }
        type
        isNew
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      userID
      instructor {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      description
      date
      categoryFilter
      category {
        barre
        bound
        crossfit
        core
        circuitTraining
        dance
        HIIT
        kickBoxing
        meditation
        pilates
        power
        pregnancy
        sculpt
        strength
        stretch
        yoga
        zumba
        cardio
        endurance
        jump
        foamRoll
        run
        yogaSculpt
        restorative
        recovery
        strengthAndConditioning
        flow
        boxing
        celebrity
        weightLoss
        bodyWeight
        stability
        mobility
        functionalTraining
        glutes
        legs
        arms
        performanceTraining
        flexibility
        spiritualWellness
        wellness
        breathWork
        beginner
        advanced
        kettleBell
        tabata
        bodyBuilding
        athleteTraining
        cycling
        plusSize
        other
      }
      duration
      equipment
      banner {
        bucket
        region
        key
      }
      intervals {
        name
        duration
        timeType
        type
        id
      }
      classStatus
      classStatusString
      price
      startTime
      defaultBannerIndex
      classBookings {
        items {
          id
          classID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stream {
        id
        isHostInStreamRoom
        classID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      playlist
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClasses = /* GraphQL */ `
  query ListClasses(
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClasses = /* GraphQL */ `
  query SyncClasses(
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStream = /* GraphQL */ `
  query GetStream($id: ID!) {
    getStream(id: $id) {
      id
      isHostInStreamRoom
      classID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStreams = /* GraphQL */ `
  query ListStreams(
    $filter: ModelStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isHostInStreamRoom
        classID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStreams = /* GraphQL */ `
  query SyncStreams(
    $filter: ModelStreamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStreams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isHostInStreamRoom
        classID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWaitList = /* GraphQL */ `
  query GetWaitList($id: ID!) {
    getWaitList(id: $id) {
      id
      email
      teach
      take
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWaitLists = /* GraphQL */ `
  query ListWaitLists(
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        teach
        take
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWaitLists = /* GraphQL */ `
  query SyncWaitLists(
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWaitLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        teach
        take
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFavoriteInstructor = /* GraphQL */ `
  query GetFavoriteInstructor($id: ID!) {
    getFavoriteInstructor(id: $id) {
      id
      userID
      user {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      instructorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFavoriteInstructors = /* GraphQL */ `
  query ListFavoriteInstructors(
    $filter: ModelFavoriteInstructorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoriteInstructors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        instructorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFavoriteInstructors = /* GraphQL */ `
  query SyncFavoriteInstructors(
    $filter: ModelFavoriteInstructorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavoriteInstructors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        user {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        instructorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrainerApplication = /* GraphQL */ `
  query GetTrainerApplication($id: ID!) {
    getTrainerApplication(id: $id) {
      id
      firstName
      lastName
      username
      isInfluencer
      isTrainer
      isOwner
      submitted
      location {
        city
        state
        zip
      }
      socials {
        instagram
        twitter
        facebook
        snapchat
        linkedin
      }
      gymStudioName
      website
      specializations {
        barre
        bound
        crossfit
        core
        circuitTraining
        dance
        HIIT
        kickBoxing
        meditation
        pilates
        power
        pregnancy
        sculpt
        strength
        stretch
        yoga
        zumba
        cardio
        endurance
        jump
        foamRoll
        run
        yogaSculpt
        restorative
        recovery
        strengthAndConditioning
        flow
        boxing
        celebrity
        weightLoss
        bodyWeight
        stability
        mobility
        functionalTraining
        glutes
        legs
        arms
        performanceTraining
        flexibility
        spiritualWellness
        wellness
        breathWork
        beginner
        advanced
        kettleBell
        tabata
        bodyBuilding
        athleteTraining
        cycling
        plusSize
        other
      }
      userID
      lastIndex
      gymStudioAddress {
        streetAddress
        city
        state
        zip
        country
      }
      files {
        items {
          id
          name
          ownerName
          trainerApplicationID
          userID
          fileType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      approved
      dateApproved
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTrainerApplications = /* GraphQL */ `
  query ListTrainerApplications(
    $filter: ModelTrainerApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainerApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        username
        isInfluencer
        isTrainer
        isOwner
        submitted
        location {
          city
          state
          zip
        }
        socials {
          instagram
          twitter
          facebook
          snapchat
          linkedin
        }
        gymStudioName
        website
        specializations {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        userID
        lastIndex
        gymStudioAddress {
          streetAddress
          city
          state
          zip
          country
        }
        files {
          nextToken
          startedAt
        }
        approved
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrainerApplications = /* GraphQL */ `
  query SyncTrainerApplications(
    $filter: ModelTrainerApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrainerApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        username
        isInfluencer
        isTrainer
        isOwner
        submitted
        location {
          city
          state
          zip
        }
        socials {
          instagram
          twitter
          facebook
          snapchat
          linkedin
        }
        gymStudioName
        website
        specializations {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        userID
        lastIndex
        gymStudioAddress {
          streetAddress
          city
          state
          zip
          country
        }
        files {
          nextToken
          startedAt
        }
        approved
        dateApproved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      ownerName
      file {
        bucket
        region
        key
      }
      trainerApplicationID
      userID
      fileType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerName
        file {
          bucket
          region
          key
        }
        trainerApplicationID
        userID
        fileType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFiles = /* GraphQL */ `
  query SyncFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        ownerName
        file {
          bucket
          region
          key
        }
        trainerApplicationID
        userID
        fileType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByUserType = /* GraphQL */ `
  query UserByUserType(
    $userType: UserTypeENUM!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUserType(
      userType: $userType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        firstName
        lastName
        phoneNumber
        avatar {
          bucket
          region
          key
        }
        customerID
        accountID
        credits
        files {
          nextToken
          startedAt
        }
        application {
          id
          firstName
          lastName
          username
          isInfluencer
          isTrainer
          isOwner
          submitted
          gymStudioName
          website
          userID
          lastIndex
          approved
          dateApproved
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        cognitoUserID
        classes {
          nextToken
          startedAt
        }
        profile {
          firstName
          lastName
          bio
          website
        }
        userType
        userTypeString
        classCategories {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        categoryFilter
        classBookings {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        favorites {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
        transactions {
          nextToken
          startedAt
        }
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const notificationsByUserID = /* GraphQL */ `
  query NotificationsByUserID(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        pushToken
        content {
          title
          body
          data
        }
        type
        isNew
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const classByUserID = /* GraphQL */ `
  query ClassByUserID(
    $userID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classByUserID(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const classByClassStatus = /* GraphQL */ `
  query ClassByClassStatus(
    $classStatusString: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classByClassStatus(
      classStatusString: $classStatusString
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        instructor {
          id
          email
          username
          firstName
          lastName
          phoneNumber
          customerID
          accountID
          credits
          cognitoUserID
          userType
          userTypeString
          categoryFilter
          pushToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        description
        date
        categoryFilter
        category {
          barre
          bound
          crossfit
          core
          circuitTraining
          dance
          HIIT
          kickBoxing
          meditation
          pilates
          power
          pregnancy
          sculpt
          strength
          stretch
          yoga
          zumba
          cardio
          endurance
          jump
          foamRoll
          run
          yogaSculpt
          restorative
          recovery
          strengthAndConditioning
          flow
          boxing
          celebrity
          weightLoss
          bodyWeight
          stability
          mobility
          functionalTraining
          glutes
          legs
          arms
          performanceTraining
          flexibility
          spiritualWellness
          wellness
          breathWork
          beginner
          advanced
          kettleBell
          tabata
          bodyBuilding
          athleteTraining
          cycling
          plusSize
          other
        }
        duration
        equipment
        banner {
          bucket
          region
          key
        }
        intervals {
          name
          duration
          timeType
          type
          id
        }
        classStatus
        classStatusString
        price
        startTime
        defaultBannerIndex
        classBookings {
          nextToken
          startedAt
        }
        stream {
          id
          isHostInStreamRoom
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        playlist
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
