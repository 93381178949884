import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, TextInput } from 'react-native'
import { signUpForm } from '../../apollo/cache'
import { Auth, Hub } from 'aws-amplify'
import styled from 'styled-components/native'
import { AntDesign } from '@expo/vector-icons'
import { ContinueButton } from 'components/ContinueButton'
import colors from 'colors'
import { StandardContainer, FieldContainer } from 'commons/containers'
import * as screenNames from 'screenNames'
import { useReactiveVar, useLazyQuery } from '@apollo/client'
import { useMutation } from 'apollo-augmented-hooks'
import { CREATE_USER } from 'apollo/mutations'
import { formatToAWSPhone } from 'helpers'
import { StyledTextInput, InputFieldTitleText } from 'components/InputField'
import { Inter500, TitleText } from 'typography'
import { AUTH_CHANNEL } from 'apollo/channelTypes'
import { LIST_USERS } from 'apollo/queries'
import { FooterBar } from 'commons/components/FooterBar'
import PhoneInput from 'react-phone-number-input/input'
import { useDimensions } from 'dimensions'
const resendTimeoutms = 15000
const testingEmail = 'dev@test.com'
const noop = () => {}

const VerificationScreen = ({ navigation }) => {
    const { isMobileWeb } = useDimensions()
    const { email, firstName, lastName, password } = useReactiveVar(signUpForm) || {}
    const [cognitoUserID, setCognitoUserID] = useState(null)
    const [phoneSelected, setPhoneSelected] = useState(false)
    const [emailSelected, setEmailSelected] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    const [resendable, setResendable] = useState(true)
    const [phone_number, setPhoneNumber] = useState('')
    const [code, setCode] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [codeError, setCodeError] = useState('')
    const [signupLoading, setSignupLoading] = useState(false)
    const [isPhoneNumberRegistered, { data: duplicatePhoneData }] = useLazyQuery(LIST_USERS, {
        onCompleted: () => {
            const isUnique = duplicatePhoneData?.listUsers?.items?.length == 0
            if (isUnique) {
                signUp({ isPhone: true })
            } else {
                setPhoneError(
                    'This phone number has already been used to create an account. Please use a new phone number.',
                )
            }
        },
        onError: () => {
            setPhoneError('Error sending verification code.')
        },
    })
    const createUser = useMutation(CREATE_USER, {
        onCompleted: handleLogin,
        onError: error => {
            __DEV__ && console.log('Error creating User: ', { error })
        },
    })
    let subHeaderText = 'Verify by phone or email'
    if (codeSent && emailSelected) subHeaderText = 'Enter the verification code received at\n\n' + email
    if (codeSent && phoneSelected) subHeaderText = 'Enter the verification code received at:\n\n' + phone_number
    else if (phoneSelected) subHeaderText = 'Enter your phone number below'

    let headerText = 'Verification'
    if (codeSent) headerText = 'Verification Code'
    else if (phoneSelected) headerText = 'Phone number'
    else if (emailSelected) headerText = 'Check Email'

    async function signUp({ isPhone }) {
        const attributes = !isPhone
            ? { email, 'custom:firstName': firstName, 'custom:lastName': lastName }
            : { email, phone_number, 'custom:firstName': firstName, 'custom:lastName': lastName }
        if (email === testingEmail) {
            setCodeSent(true)
        } else {
            try {
                const { userSub } = await Auth.signUp({
                    username: email,
                    password: password,
                    attributes,
                })
                setCognitoUserID(userSub)
                setCodeSent(true)
            } catch (error) {
                __DEV__ && console.log('Error signing up', error)
            }
        }
    }

    async function handleLogin() {
        try {
            await Auth.signIn(email, password)
            navigation.navigate(isMobileWeb ? screenNames.TEST_FLIGHT_DOWNLOAD : screenNames.ONBOARDING)
        } catch (error) {
            __DEV__ && console.log('Error signing in: ', error)
        }
    }

    function handlePhoneVerification() {
        if (phone_number?.length == 12) {
            isPhoneNumberRegistered({ variables: { filter: { phoneNumber: { eq: formatToAWSPhone(phone_number) } } } })
        } else {
            setPhoneError('Please enter a valid 10 digit phone number.')
        }
    }

    function handleEmailVerification() {
        setEmailSelected(true)
        signUp({ isPhone: false })
    }

    async function handleConfirmSignUp() {
        const { email } = signUpForm()
        if (email === testingEmail) navigation.navigate(screenNames.ONBOARDING)
        try {
            setSignupLoading(true)
            const confirmation = await Auth.confirmSignUp(email, code)
            if (confirmation === 'SUCCESS') {
                let userDetails = {
                    id: cognitoUserID,
                    firstName,
                    lastName,
                    profile: {
                        firstName,
                        lastName,
                    },
                    email,
                    cognitoUserID,
                }
                if (!emailSelected) {
                    userDetails = { ...userDetails, phoneNumber: formatToAWSPhone(phone_number) }
                }
                setSignupLoading(false)
                Hub.dispatch(AUTH_CHANNEL.AUTH, {
                    data: { createUser, userDetails },
                    event: AUTH_CHANNEL.CONFIRM_SIGN_UP,
                })
            }
        } catch (error) {
            setSignupLoading(false)
            __DEV__ && console.log('Error confirming sign up', error)
            if (error?.code == 'CodeMismatchException') {
                setCodeError('Invalid verification code provided.')
            }
        }
    }

    async function handleResendCode() {
        try {
            const result = await Auth.resendSignUp(email)
            setResendable(false)
            setTimeout(() => {
                setResendable(true)
            }, resendTimeoutms)
        } catch (error) {
            __DEV__ && console.log('Error resending code: ', error)
        }
    }

    function handleBackButton() {
        if (!phoneSelected && !emailSelected) navigation.goBack()
        if (phoneSelected || emailSelected) {
            setPhoneSelected(false)
            setEmailSelected(false)
            setCodeSent(false)
        }
    }

    const clearPhoneError = () => (phoneError ? setPhoneError('') : noop)
    const clearCodeError = () => (codeError ? setCodeError('') : noop)

    return (
        <>
            <StandardContainer>
                <FieldContainer>
                    <TitleContainer>
                        <TitleText paddingLeft={headerText == 'Phone number' ? 4 : 0} paddingBottom={10}>
                            {headerText}
                        </TitleText>
                        <TouchableOpacity style={{ height: 40, width: 50, justifyContent: 'center' }}>
                            <AntDesign
                                onPress={handleBackButton}
                                name="arrowleft"
                                size={30}
                                style={{ alignSelf: 'flex-end' }}
                            />
                        </TouchableOpacity>
                    </TitleContainer>
                    <SubHeaderText style={{ marginBottom: codeSent ? 15 : 50, paddingLeft: 5 }}>
                        {subHeaderText}
                    </SubHeaderText>
                    {codeSent && (
                        <TryAgainText style={{ marginBottom: 35, paddingLeft: 5 }}>
                            Didn't recieve a code?{' '}
                            <TryAgainText
                                onPress={resendable ? handleResendCode : () => {}}
                                style={{ color: resendable ? colors.blueContinue : '' }}
                            >
                                Try again
                            </TryAgainText>
                        </TryAgainText>
                    )}
                    {!phoneSelected && !emailSelected && !codeSent ? (
                        <>
                            <ContinueButton
                                onPress={() => setPhoneSelected(true)}
                                text="Phone"
                                style={{ marginBottom: 30, height: 56, width: 363 }}
                                color={colors.blueContinue}
                            />
                            <ContinueButton
                                onPress={handleEmailVerification}
                                text="Email"
                                style={{ height: 56, width: 363 }}
                                color={colors.blueContinue}
                            />
                        </>
                    ) : phoneSelected && !codeSent ? (
                        <>
                            <InputFieldTitleText style={{ color: !!phoneError ? 'red' : 'black' }}>
                                {!!phoneError ? phoneError : 'Phone number'}
                            </InputFieldTitleText>
                            <PhoneInput
                                style={{
                                    height: 50,
                                    overflow: 'hidden',
                                    backgroundColor: colors.inputFieldGray,
                                    borderWidth: 1,
                                    borderColor: !!phoneError ? 'red' : 'transparent',
                                    borderRightColor: !!phoneError ? 'red' : 'transparent',
                                    borderTopColor: !!phoneError ? 'red' : 'transparent',
                                    borderBottomColor: !!phoneError ? 'red' : 'transparent',
                                    outlineColor: !!phoneError ? 'red' : colors.homebodyTurquoise,
                                    borderRadius: 10,
                                    paddingLeft: 10,
                                    marginTop: 10,
                                    marginBottom: 49,
                                }}
                                onFocus={clearPhoneError}
                                errorMessage={codeError}
                                defaultCountry="US"
                                value={phone_number}
                                onChange={setPhoneNumber}
                            />
                            <ContinueButton
                                onPress={handlePhoneVerification}
                                text="Continue"
                                color={colors.blueContinue}
                            />
                        </>
                    ) : (
                        <>
                            <StyledTextInput
                                maxLength={6}
                                value={code}
                                onFocus={clearCodeError}
                                errorMessage={codeError}
                                onChangeText={text => setCode(text.replace(/[^+0-9]/g, ''))}
                            />
                            <ContinueButton
                                onPress={
                                    code.length === 6
                                        ? () => handleConfirmSignUp()
                                        : () => setCodeError('Please enter the 6 digit code you received.')
                                }
                                style={{
                                    marginTop: 49,
                                }}
                                actionLoading={signupLoading}
                                text="Submit"
                                color={colors.blueContinue}
                            />
                        </>
                    )}
                </FieldContainer>
            </StandardContainer>
            {!isMobileWeb && <FooterBar />}
        </>
    )
}

export const TitleContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const TryAgainText = styled(Inter500)`
    font-size: 15px;
    line-height: 16px;
    color: ${colors.subTextGray};
`

export const SubHeaderText = styled(Inter500)`
    margin-top: 15px;
    font-size: 20px;
    line-height: 16px;
    text-align: left;
    align-self: flex-start;
    color: ${colors.subTextGray};
`
export default VerificationScreen
