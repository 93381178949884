import React, { useState, useEffect } from 'react'
import { View, Text, Linking } from 'react-native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { Headline } from 'styles/typography'
import { RowContainer } from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import PayoutHistory from 'commons/components/instructor/PayoutHistory'
import { FooterBar } from 'commons/components/FooterBar'
//apollo
import { getCurrentUser, getAllClasses } from 'apollo/selectors'
//Stripe
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
const { CREATE_ACCOUNT_LINK, CREATE_LOGIN_LINK, CREATE_TRANSFER } = STRIPE_API_ROUTES

function Transactions({ navigation, route }) {
    const { id: userID, accountID } = getCurrentUser()
    const [onboarding, setOnboarding] = useState([])
    const [login, setLogin] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function createTransfer() {
            const amount = Math.floor(Math.random() * 5000)
            const payout = Math.floor((amount / 10) * 8)
            const transfer = await stripeAPI({
                path: CREATE_TRANSFER,
                body: {
                    accountID,
                    className: 'Class Metadata',
                    userID,
                    amount: 8,
                    total: 10,
                },
            })
        }

        async function getLinks() {
            const onboardingLink = await stripeAPI({
                path: CREATE_ACCOUNT_LINK,
                body: { accountID },
            })

            const loginLink = await stripeAPI({
                path: CREATE_LOGIN_LINK,
                body: { accountID },
            })

            setOnboarding(onboardingLink)
            setLogin(loginLink)
        }

        if (accountID) {
            getLinks()
        }
    }, [accountID])

    useEffect(() => {
        onboarding !== '' && login !== '' && setLoading(false)
    }, [onboarding, login])

    const goToOnboarding = () => {
        Linking.openURL(onboarding?.url)
    }
    const goToLogin = () => {
        Linking.openURL(login?.url)
    }

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <Headline isLargeHeadline isMostBold paddingBottom={25}>
                    Your Payouts
                </Headline>
                <TopRowContainer>
                    <PaymentView />
                    <EarningView>
                        <ContinueButton
                            onPress={goToOnboarding}
                            text="Stripe Onboarding"
                            disabled={loading || onboarding?.type}
                            actionLoading={loading}
                            color={onboarding?.type ? colors.cancelClassRed : colors.homebodyTurquoise}
                        />
                        <ContinueButton
                            onPress={goToLogin}
                            text="Stripe Login"
                            disabled={loading || login?.type}
                            actionLoading={loading}
                            color={login?.type ? colors.cancelClassRed : colors.homebodyTurquoise}
                        />
                    </EarningView>
                </TopRowContainer>
                <PayoutHistory accountID={accountID} />
            </ContentContainer>

            <FooterBar />
        </ScreenContainer>
    )
}
export default Transactions

const TopRowContainer = styled(RowContainer)`
    padding-vertical: 3%;
`
const PaymentView = styled(View)`
    width: 515px;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
const EarningView = styled(View)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 301px;
    height: 200px;
    margin-left: 34px;
    border-radius: 20px;
    padding-horizontal: 10px;
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
