import { Dimensions, Platform } from 'react-native'
import colors from 'colors'
import Constants from 'expo-constants'
const emptyObj = {}
export const IS_DEV = process.env.APP_VARIANT == 'DEVELOPMENT'
export const isWeb = Platform.OS == 'web'
export const isAndroid = Platform.OS == 'android'
export const isIOS = Platform.OS == 'ios'
export const isNative = isIOS || isAndroid
export const iconHitSlop = { top: 20, bottom: 20, left: 20, right: 20 }
export const HEADER_HEIGHT = isWeb ? 80 : 70
export const INSTRUCTOR_HEADER_HEIGHT = 100
export const BOTTOM_TAB_BAR_HEIGHT = 70
export const { height: SCREEN_HEIGHT, width: SCREEN_WIDTH } = Dimensions.get('window')
export const USER_TYPES = { ADMIN: 'ADMIN', INSTRUCTOR: 'INSTRUCTOR', TRAINEE: 'TRAINEE' }
export const APPLICATION_STATUS = { PENDING: 'PENDING', APPROVED: 'APPROVED', DENIED: 'DENIED' }
export const FILE_TYPES = { CERTIFICATION: 'CERTIFICATION', BUSINESS: 'BUSINESS' }
export const CLASS_STATUS = {
    UNFINISHED: 'UNFINISHED',
    UNPUBLISHED: 'UNPUBLISHED',
    PUBLISHED: 'PUBLISHED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED',
    PAID_OUT: 'PAID_OUT',
}
export const APOLLO_FETCH_POLICIES = {
    CACHE_FIRST: 'cache-first',
    CACHE_ONLY: 'cache-only',
    CACHE_AND_NETWORK: 'cache-and-network',
    NETWORK_ONLY: 'network-only',
    NO_CACHE: 'no-cache',
}

export const INSTRUCTOR_DRAWER_WIDTH = 225

export const BUTTON_BORDER_RADIUS = '10px'

export const linearGradientConfig = {
    colors: [colors.homebodyTurquoise, colors.homebodyGreen],
    start: { x: 0.15, y: 0 },
    end: { x: 0.85, y: 0 },
}

export const STRIPE_ELEMENT_TYPES = { CARD_NUMBER: 'CARD_NUMBER', CARD_EXPIRY: 'CARD_EXPIRY', CARD_CVC: 'CARD_CVC' }

export const NOTIFICATION_TYPES = {
    ENROLLED_IN_CLASS: 'ENROLLED_IN_CLASS',
    CLASS_CANCELLED: 'CLASS_CANCELLED',
    CLASS_STARTING_SOON: 'CLASS_STARTING_SOON',
    FAVORITE_INSTRUCTOR_CLASS: 'FAVORITE_INSTRUCTOR_CLASS',
    LEAVE_CLASS_REVIEW: 'LEAVE_CLASS_REVIEW',
    TRAINEE_LEFT_REVIEW: 'TRAINEE_LEFT_REVIEW',
    INSTRUCTOR_CLASS_STARTING_SOON: 'INSTRUCTOR_CLASS_STARTING_SOON',
}

export const SANITIZE_INPUT_TYPES = {
    NUMERIC_INPUT: /[^+0-9]/g,
    NO_SPACES: /\s/g,
    USERNAME_INPUT: /[~`!@#$%^&*()+={}\[\];:\'\"<>,\/\\\s[A-Z]/g,
}

export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
}

const { GET, POST } = HTTP_METHODS

export const STRIPE_API_ROUTES = {
    // Test request
    HELLO: { method: GET, endPoint: '/hello' },

    // Customer requests
    CREATE_CUSTOMER: { method: POST, endPoint: '/stripe/customers/createCustomer' },
    GET_CUSTOMER: { method: GET, endPoint: '/stripe/customers/getCustomer' },
    LIST_CUSTOMERS: { method: GET, endPoint: '/stripe/customers/listCustomers' },

    // Payment method request
    GET_SECRET: { method: GET, endPoint: '/stripe/payments/getSecret' },
    LIST_PAYMENT_METHODS: { method: GET, endPoint: '/stripe/payments/listPaymentMethods' },
    CREATE_PAYMENT_METHOD: { method: POST, endPoint: '/stripe/payments/createPaymentMethod' },
    ADD_CARD: { method: POST, endPoint: '/stripe/payments/addCard' },
    DELETE_CARD: { method: POST, endPoint: '/stripe/payments/deleteCard' },
    UPDATE_DEFAULT_PAYMENT_METHOD: { method: POST, endPoint: '/stripe/payments/updateDefaultPaymentMethod' },

    // Product requests
    GET_PRODUCT: { method: GET, endPoint: '/stripe/products/getProduct' },
    LIST_PRODUCTS: { method: GET, endPoint: '/stripe/products/listProducts' },

    // Price requests
    GET_PRICE: { method: GET, endPoint: '/stripe/prices/getPrice' },
    LIST_PRICES: { method: GET, endPoint: '/stripe/prices/listPrices' },

    // Charge requests
    GET_CHARGE: { method: GET, endPoint: '/stripe/charge/getCharge' },
    LIST_CHARGES: { method: GET, endPoint: '/stripe/charge/listCharges' },
    CREATE_CHARGE: { method: POST, endPoint: '/stripe/charge/createCharge' },

    // Payment Intent requests
    GET_INTENT: { method: GET, endPoint: '/stripe/intent/getPaymentIntent' },
    LIST_INTENTS: { method: GET, endPoint: '/stripe/intent/listPaymentIntents' },
    CREATE_INTENT: { method: POST, endPoint: '/stripe/intent/createPaymentIntent' },

    // Subscription requests
    CREATE_SUBSCRIPTION: { method: POST, endPoint: '/stripe/subscription/createSubscription' },
    LIST_SUBSCRIPTIONS: { method: GET, endPoint: '/stripe/subscription/listSubscriptions' },
    CANCEL_SUBSCRIPTION: { method: POST, endPoint: '/stripe/subscription/cancelSubscription' },

    // Invoice requests
    GET_INVOICE: { method: GET, endPoint: '/stripe/invoice/getInvoice' },
    LIST_INVOICES: { method: GET, endPoint: '/stripe/invoice/listInvoices' },

    // Stripe connect requests
    CREATE_STRIPE_CONNECT_ACCOUNT: { method: POST, endPoint: '/stripe/connect/createStripeConnectAccount' },
    GET_STRIPE_CONNECT_ACCOUNT: { method: GET, endPoint: '/stripe/connect/getStripeConnectAccount' },
    CREATE_ACCOUNT_LINK: { method: POST, endPoint: '/stripe/connect/createAccountLink' },
    CREATE_LOGIN_LINK: { method: POST, endPoint: '/stripe/connect/createLoginLink' },
    CREATE_PAYOUT: { method: POST, endPoint: '/stripe/connect/createPayout' },
    GET_PAYOUT: { method: GET, endPoint: '/stripe/connect/getPayout' },
    LIST_PAYOUTS: { method: GET, endPoint: '/stripe/connect/listPayouts' },
    CREATE_TRANSFER: { method: POST, endPoint: '/stripe/connect/createTransfer' },
    LIST_TRANSFERS: { method: GET, endPoint: '/stripe/connect/listTransfers' },

    // In app credit requests
    PURCHASE_CLASS: { method: POST, endPoint: '/credits/purchaseClass' },
    WITHDRAW_FROM_CLASS: { method: POST, endPoint: '/credits/withdrawFromClass' },

    // Revenue cat requests
    GET_OFFERINGS: { method: GET, endPoint: '/revenueCat/getOfferings' },
}

export const TRANSACTION_TYPES = {
    CLASS_PURCHASED: 'CLASS_PURCHASED',
    CLASS_REFUNDED: 'CLASS_REFUNDED',
}

export const TRANSACTION_RECORD_TYPES = {
    CREDIT_TRANSACTION: 'CREDIT_TRANSACTION',
    INTENT_TRANSACTION: 'INTENT_TRANSACTION',
    SUBSCRIPTION_TRANSACTION: 'SUBSCRIPTION_TRANSACTION',
    APPLE_CONSUMABLE_TRANSACTION: 'APPLE_CONSUMABLE_TRANSACTION',
    APPLE_SUBSCRIPTION_TRANSACTION: 'APPLE_SUBSCRIPTION_TRANSACTION',
}

export const REV_CAT_APPLE_PRODUCT_CREDITS = {
    ['com.homebody.homebodyapp.prod_5_cred_consumable']: 5,
    ['com.homebody.homebodyapp.prod_15_cred_sub']: 15,
    ['com.homebody.homebodyapp.prod_25_cred_sub']: 25,
    ['com.homebody.homebodyapp.prod_50_cred_sub']: 50,
    ['com.homebody.homebodyapp.prod_100_cred_sub']: 100,
}

export const REV_CAT_PRODUCT_CREDITS = REV_CAT_APPLE_PRODUCT_CREDITS

export const PAGINATED_LIMIT = 20

/* 
    I know what you're thinking, why not just store the web env variables with: process.env.APP_MANIFEST.extra?
    I'll tell you why, it crashes inexplicably, however, storing the values of process.env and process.env.APP_MANIFEST 
    separately does not cause a crash so that's what we're going with.
*/
const env = process.env ?? emptyObj
const manifest = env?.APP_MANIFEST ?? emptyObj
const webEnvironmentVariables = manifest?.extra?.eas ?? emptyObj
// "Constants?.manifest2" retrieves env variables from the eas update manifest, "Constants?.manifest" retrieves env variables from the build manifest
const nativeEnvironmentVariables =
    Constants?.manifest?.extra?.eas ?? Constants?.manifest2?.extra?.expoClient?.extra?.eas ?? emptyObj
const RUNTIME_VERSION = Constants?.manifest?.runtimeVersion ?? Constants?.manifest2?.runtimeVersion
const MANIFEST = Constants?.manifest
export const ENVIRONMENT_VARIABLES = isNative
    ? { ...nativeEnvironmentVariables, RUNTIME_VERSION, MANIFEST }
    : webEnvironmentVariables
