import { makeVar, InMemoryCache } from '@apollo/client'
import { offsetLimitPagination, relayStylePagination } from '@apollo/client/utilities'
import {
    initialSignUpForm,
    initialInstructorApplicationForm,
    initialCreateClassForm,
    initialUserProfileForm,
    initialAgoraStreamState,
    initialPaymentMethodForm,
} from 'initialFormStates'
const emptyObj = {}
const emptyArr = []

export const currentUserID = makeVar('')
export const searchBody = makeVar('')
export const signUpForm = makeVar(initialSignUpForm)
export const instructorApplicationForm = makeVar(initialInstructorApplicationForm)
export const createClassForm = makeVar(initialCreateClassForm)
export const userProfileForm = makeVar(initialUserProfileForm)
export const isLoggedIn = makeVar(false)
export const agoraStreamState = makeVar(initialAgoraStreamState)
export const paymentMethodForm = makeVar(initialPaymentMethodForm)

function customMergePolicy({ field, typename = true, showLogs = false, checkField = 'userID' }) {
    return {
        merge(existing, incoming, { readField, mergeObjects }) {
            showLogs && console.log(field, 'merge', existing, 'incoming', incoming)
            var existingData = Array.isArray(existing)
                ? existing?.filter(item => readField('_deleted', item) !== true)
                : existing?.items?.filter(item => readField('_deleted', item) !== true) || emptyArr
            var incomingData = Array.isArray(incoming)
                ? incoming?.filter(item => readField('_deleted', item) !== true)
                : incoming?.items?.filter(item => readField('_deleted', item) !== true) || emptyArr
            if (!existing || existingData.length === 0) {
                return typename ? { ...incoming, items: incomingData } : incomingData
            }

            const merged = existingData

            const itemIdToIndex = new Map(
                merged?.map((item, index) => [readField('id', item), index]),
                [merged],
            )

            incomingData.forEach(item => {
                const id = readField('id', item)
                const index = itemIdToIndex.get(id)
                if (typeof index === 'number') {
                    // Merge the new item data with the existing item data.
                    merged[index] = mergeObjects(merged[index], item)
                } else {
                    // First time we've seen this item in this array.
                    itemIdToIndex[id] = merged?.length
                    merged?.push(item)
                }
            })
            const result = typename ? { ...incoming, items: merged } : merged
            showLogs && console.log(field, 'merge result', result)
            return result
        },
        read(existing, { readField, variables = emptyObj, args }) {
            showLogs && console.log(field, 'read', existing, 'variables', variables, 'args', args)
            if (existing || existing?.items || existing?.items?.length !== 0) {
                var existingData = Array.isArray(existing) ? existing : existing?.items || emptyArr
                const id = variables?.id || ''
                const filter = variables?.filter || emptyObj
                if (filter !== emptyObj && showLogs) {
                    console.log(field, 'filter: ', filter)
                    console.log(field, 'Object.entries(filter): ', Object.entries(filter))
                }
                const values =
                    variables !== emptyObj && existingData !== emptyArr
                        ? existingData?.filter(item => readField(checkField, item) === id)
                        : existingData
                const result = typename ? { ...existing, items: values } : values
                showLogs && console.log(field, 'read result', result)
                return result
            }
        },
    }
}

export const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        User: {
            merge: true,
            fields: {
                classes: customMergePolicy({
                    field: 'user classes',
                    showLogs: false,
                    checkField: 'id',
                }),
                files: customMergePolicy({ field: 'user files' }),
                favorites: customMergePolicy({ field: 'user favorites', showLogs: false }),
                classBookings: customMergePolicy({
                    field: 'user bookings',
                    showLogs: false,
                }),
                reviews: customMergePolicy({ field: 'user reviews' }),
                notifications: customMergePolicy({ field: 'user notifications' }),
                transactions: customMergePolicy({ field: 'user transactions' }),
                profile: {
                    merge: true,
                },
                credits: {
                    merge: true,
                },
            },
        },
        Class: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
            fields: {
                classBookings: customMergePolicy({
                    field: 'class bookings',
                    showLogs: false,
                    checkField: 'classID',
                }),
            },
        },
        File: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        Notification: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        FavoriteInstructor: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        Review: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        ClassBooking: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID', 'classID'],
        },
        Transactions: customMergePolicy({ field: 'Transactions', typename: false }),
        TrainerApplication: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
        },
        Query: {
            fields: {
                getCurrentUserID: {
                    read() {
                        return currentUserID()
                    },
                },
                isLoggedIn: {
                    read() {
                        return !!currentUserID()
                    },
                },
            },
        },
    },
})
