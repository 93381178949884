import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { SafeAreaView, AppState, Alert } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import * as Sentry from 'sentry-expo'
import * as Updates from 'expo-updates'
//------------------ Navigators ------------------
import * as screenNames from 'screenNames'
import NavigationHeaderOrDrawer from 'src/navigators/NavigationHeaderOrDrawer'
import { InstructorDrawerNavigator } from './navigators/InstructorNavigators'
import { unmountScreenOnBlur, getInitialRoute } from 'navigationActions'
//------------------ Screens ------------------
import SignUpScreen from 'screens/Auth/SignUpScreen'
import VerificationScreen from 'screens/Auth/VerificationScreen'
import LoginScreen from 'screens/Auth/LoginScreen'
import LandingPage from 'screens/home/LandingPage'
import SupportPage from 'screens/home/SupportPage'
import PrivacyPage from 'screens/privacy/PrivacyPage'
import TermsOfServicePage from 'screens/termsOfService/TermsOfService'
import PressKitPage from 'screens/pressKit/PressKitPage'
import SplashScreen from './screens/SplashScreen'
import ApplicationForm from 'screens/InstructorApplication/ApplicationForm'
import StartTrainerApp from './screens/InstructorApplication/StartInstructorApp'
import ClassDetails from './screens/common/ClassDetails'
import AdminInstructorApplicationReview from './screens/InstructorApplication/AdminInstructorApplicationReview'
import TestFlightDownload from 'screens/trainee/TestFlightDownload'
//------------------ Apollo ------------------
import { gql, useReactiveVar, useApolloClient } from '@apollo/client'
import { getUser } from '@queries'
//------------------ Misc ------------------
import { useDimensions } from 'dimensions'
import { isWeb, isNative, USER_TYPES } from '@constants'
const { TRAINEE, INSTRUCTOR, ADMIN } = USER_TYPES
import { currentUserID, agoraStreamState } from 'apollo/cache'
import { Auth } from 'aws-amplify'
import TraineeNavigator from './navigators/TraineeNavigators'
import Onboarding from './screens/Auth/OnboardingScreen'
import { TraineeLogoHeader } from 'components/trainee/TraineeNativeHeader'

const emptyObj = {}

const Stack = createStackNavigator()
const Drawer = createDrawerNavigator()

const InstructorApplicationScreensArray = Object.values(screenNames.INSTRUCTOR_APPLICATION).filter(
    name =>
        name != screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP &&
        name != screenNames.INSTRUCTOR_APPLICATION.ADMIN_REVIEW &&
        name != screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR,
)
const InstructorApplicationScreens = () => (
    <Stack.Navigator
        initialRouteName={screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP}
        screenOptions={{ headerShown: false }}
    >
        <Stack.Screen name={screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP} component={StartTrainerApp} />
        {InstructorApplicationScreensArray.map(name => (
            <Stack.Screen key={name} name={name} options={{ title: name }} component={ApplicationForm} />
        ))}
        <Stack.Screen
            name={screenNames.INSTRUCTOR_APPLICATION.ADMIN_REVIEW}
            component={AdminInstructorApplicationReview}
        />
    </Stack.Navigator>
)

export default function Navigator() {
    const client = useApolloClient()
    const { isDesktop, isMobile, isMobileWeb } = useDimensions()
    const DEV_DEFAULT_INITIAL_ROUTE = isMobileWeb
        ? screenNames.TEST_FLIGHT_DOWNLOAD
        : isWeb
        ? screenNames.INSTRUCTOR_NAVIGATOR
        : screenNames.TRAINEE_NAVIGATOR
    const DEFAULT_INITIAL_ROUTE = isNative
        ? screenNames.SIGN_UP
        : isMobileWeb
        ? screenNames.TEST_FLIGHT_DOWNLOAD
        : screenNames.LANDING_PAGE
    const [initialRoute, setInitialRoute] = useState(__DEV__ ? DEV_DEFAULT_INITIAL_ROUTE : DEFAULT_INITIAL_ROUTE)
    const [loading, setLoading] = useState(true)
    const currentUser = useReactiveVar(currentUserID)
    const [userType, setUserType] = useState(TRAINEE)
    const shouldUnmountTraineeNavigator = isMobileWeb
    const shouldIncludeInstructorNavigator = (userType === INSTRUCTOR || userType === ADMIN || __DEV__) && !isMobileWeb

    const appState = useRef(AppState.currentState)
    const [currentAppState, setCurrentAppState] = useState(appState.current)
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

    async function checkForUpdate() {
        const checkForUpdateResult = await Updates.checkForUpdateAsync()
        if (checkForUpdateResult.isAvailable) {
            const fetchUpdateResult = await Updates.fetchUpdateAsync()
            const isNewUpdate = fetchUpdateResult.isNew
            if (isNewUpdate) {
                setIsUpdateAvailable(true)
            }
        }
    }

    const updateAppAlert = async () =>
        Alert.alert('Update Available', 'The app will now be restarted to apply the update.', [
            {
                text: 'Restart',
                onPress: async () => {
                    await Updates.reloadAsync()
                },
            },
        ])

    let checkForUpdateInterval = null
    const _handleAppStateChange = useCallback(
        nextAppState => {
            if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
                if (checkForUpdateInterval) {
                    clearInterval(checkForUpdateInterval)
                }
            }
            appState.current = nextAppState
            setCurrentAppState(nextAppState)
            if (nextAppState === 'inactive' || nextAppState === 'background') {
                checkForUpdateInterval = setInterval(async () => {
                    await checkForUpdate()
                }, 1800000)
            }
        },
        [isUpdateAvailable],
    )

    useEffect(() => {
        if (isNative && !__DEV__) {
            const appStateSubscription = AppState.addEventListener('change', _handleAppStateChange)
            return () => appStateSubscription.remove()
        }
    }, [])

    useEffect(() => {
        const shouldPromptForUpdate = currentAppState === 'active' && isUpdateAvailable && isNative && !__DEV__
        shouldPromptForUpdate && updateAppAlert()
    }, [currentAppState, isUpdateAvailable])

    useEffect(() => {
        const checkSession = async () => {
            const auth = (await Auth.currentUserInfo()) || { attributes: { sub: '' } }
            if (auth?.attributes?.sub == '') {
                return setLoading(false) // user is not signed in
            } else {
                const { attributes: { sub: cognitoUserID } = {} } = auth
                const { data } = await client.query({ query: gql(getUser), variables: { id: cognitoUserID } })
                currentUserID(cognitoUserID)
                const { userType: type = TRAINEE, application } = data?.getUser ?? emptyObj
                setUserType(type)
                const { approved: applicationStatus = '', submitted = false } = application || {
                    applicationStatus: '',
                    submitted: false,
                }
                const initial = getInitialRoute({ userType, applicationStatus, submitted, isMobileWeb })
                if (!__DEV__) {
                    setInitialRoute(initial)
                }
                //setting the value of this reactive var will trigger appropriate "login" rerenders
                const { username, profile } = data?.getUser ?? {}
                Sentry?.Browser?.setContext('user', { username, profile })
                setLoading(false)
            }
        }
        checkSession()
    }, [currentUser])

    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined: isTraineeInStream } = streamState
    const safeAreaBackgroundColor = useMemo(() => (isTraineeInStream ? 'black' : 'white'), [isTraineeInStream])
    const isDrawer = isWeb && !isDesktop
    const StackOrDrawer = isDrawer ? Drawer : Stack
    if (!__DEV__ && loading) {
        return (
            <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <SplashScreen loading={loading} />
            </SafeAreaView>
        )
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: safeAreaBackgroundColor }}>
            <StackOrDrawer.Navigator
                initialRouteName={initialRoute}
                drawerContent={props => <NavigationHeaderOrDrawer isDrawer={isDrawer} {...props} />}
            >
                <StackOrDrawer.Group
                    screenOptions={({ navigation }) => ({
                        header: () =>
                            !isNative ? <NavigationHeaderOrDrawer navigation={navigation} /> : <TraineeLogoHeader />,
                        drawerPosition: 'right',
                        drawerType: 'front',
                    })}
                >
                    {isMobileWeb && (
                        <StackOrDrawer.Screen
                            name={screenNames.TEST_FLIGHT_DOWNLOAD}
                            component={TestFlightDownload}
                            options={{ title: 'Join the Beta!' }}
                        />
                    )}
                    <StackOrDrawer.Screen
                        name={screenNames.LANDING_PAGE}
                        component={LandingPage}
                        options={{ title: 'HBLIVE' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.PRESS_KIT_PAGE}
                        component={PressKitPage}
                        options={{ title: 'HBLIVE Press Kit' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.SUPPORT}
                        component={SupportPage}
                        options={{ title: 'Support' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.PRIVACY_PAGE}
                        component={PrivacyPage}
                        options={{ title: 'Privacy' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.TERMS_OF_SERVICE_PAGE}
                        component={TermsOfServicePage}
                        options={{ title: 'Terms Of Service Page' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.SIGN_UP}
                        component={SignUpScreen}
                        options={{ title: 'Sign up' }}
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.LOGIN}
                        component={LoginScreen}
                        options={{ title: 'Log in' }}
                        listeners={({ navigation }) =>
                            isNative
                                ? {
                                      blur: () => {
                                          unmountScreenOnBlur({ navigation, screenName: screenNames.LOGIN })
                                          unmountScreenOnBlur({ navigation, screenName: screenNames.SIGN_UP })
                                      },
                                  }
                                : {}
                        }
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.VERIFICATION}
                        component={VerificationScreen}
                        options={{ title: 'Verify code' }}
                        listeners={({ navigation }) =>
                            isNative
                                ? {
                                      blur: () =>
                                          unmountScreenOnBlur({ navigation, screenName: screenNames.VERIFICATION }),
                                  }
                                : {}
                        }
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.ONBOARDING}
                        component={Onboarding}
                        options={{ title: 'Welcome!' }}
                        listeners={({ navigation }) =>
                            isNative
                                ? {
                                      blur: () => {
                                          unmountScreenOnBlur({ navigation, screenName: screenNames.ONBOARDING })
                                          unmountScreenOnBlur({ navigation, screenName: screenNames.SIGN_UP })
                                      },
                                  }
                                : {}
                        }
                    />
                    <StackOrDrawer.Screen
                        name={screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR}
                        component={InstructorApplicationScreens}
                    />
                    {!shouldUnmountTraineeNavigator && (
                        <StackOrDrawer.Screen
                            name={screenNames.TRAINEE_NAVIGATOR}
                            component={TraineeNavigator}
                            options={{ headerShown: false }}
                            listeners={({ navigation }) =>
                                isNative
                                    ? {
                                          blur: () =>
                                              unmountScreenOnBlur({
                                                  navigation,
                                                  screenName: screenNames.TRAINEE_NAVIGATOR,
                                              }),
                                      }
                                    : {}
                            }
                        />
                    )}
                    <StackOrDrawer.Screen name={screenNames.CLASS_DETAILS} component={ClassDetails} />
                </StackOrDrawer.Group>
                {shouldIncludeInstructorNavigator && (
                    <StackOrDrawer.Screen
                        name={screenNames.INSTRUCTOR_NAVIGATOR}
                        component={InstructorDrawerNavigator}
                        options={{ headerShown: false }}
                    />
                )}
            </StackOrDrawer.Navigator>
        </SafeAreaView>
    )
}
