import * as DocumentPicker from 'expo-document-picker'
import { Auth, Hub, API } from 'aws-amplify'
import { AUTH_CHANNEL } from 'apollo/channelTypes'
import { Storage, StorageProvider } from 'aws-amplify'
import { instructorApplicationForm, createClassForm, userProfileForm } from 'apollo/cache'
import { initialInstructorApplicationForm } from 'initialFormStates'
import { INSTRUCTOR_APPLICATION, START_TRAINER_APP, CREATE_CLASS } from 'screenNames'
import { getExistingFiles, getTrainerApplicationAsync } from 'apollo/selectors'
import { getTodaysDateInAWSFormat, convertBytesToMB } from 'helpers'
import * as ImagePicker from 'expo-image-picker'
import * as Notifications from 'expo-notifications'
import { STRIPE_API_ROUTES } from '@constants'
const { CREATE_STRIPE_CONNECT_ACCOUNT } = STRIPE_API_ROUTES

//------------------ Apollo ------------------
import apolloClient from 'apolloClient'
import { gql } from '@apollo/client'
import { USER_TYPES, APPLICATION_STATUS, FILE_TYPES, HTTP_METHODS } from '@constants'
import { getUser } from '@queries'
const { GET, POST } = HTTP_METHODS
const { TRAINEE, INSTRUCTOR } = USER_TYPES
const { CERTIFICATION, BUSINESS } = FILE_TYPES
const { CERTIFICATIONS, BUSINESS_REGISTRATION } = INSTRUCTOR_APPLICATION
const { INTERVALS } = CREATE_CLASS
import awsExports from './aws-exports'
const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = awsExports
const emptyObj = {}
const emptyArr = []

export async function stripeAPI({ path, body, pathParameters }) {
    const stripeAPI = 'stripe-api'
    const { method, endPoint: pathEndPoint } = path || emptyObj
    const endPoint = pathParameters ? `${pathEndPoint}/${pathParameters}` : pathEndPoint
    __DEV__ && console.log('endPoint: ', endPoint)
    let response
    switch (method) {
        case GET:
            response = await API.get(stripeAPI, endPoint)
            break

        case POST:
            response = await API.post(stripeAPI, endPoint, {
                body,
            })
            break

        default:
            break
    }

    return response
}

//------------------- Auth Actions --------------------
export async function logout({ isGlobal = false } = {}) {
    try {
        await Auth.signOut(isGlobal ? { global: true } : {})
        Hub.dispatch(AUTH_CHANNEL.AUTH, {
            event: AUTH_CHANNEL.SIGN_OUT,
        })
    } catch (error) {
        __DEV__ && console.log('Error signing out: ', error)
    }
}

export async function getUserTypeAsync() {
    try {
        const auth = (await Auth.currentUserInfo()) || { attributes: { sub: '' } }
        if (auth.attributes.sub == '') return [] // user is not signed in
        const {
            attributes: { sub: cognitoUserID },
        } = auth
        const { data } = await apolloClient.query({ query: gql(getUser), variables: { id: cognitoUserID } })
        const { userType = TRAINEE, application } = data?.getUser ?? {}
        const { approved: applicationStatus = '', submitted = false } = application || {
            applicationStatus: '',
            submitted: false,
        }
        // have to add || in case userType is explicitly null in which case the default value above won't automatically become TRAINEE
        return (
            { userType, applicationStatus, submitted } || { userType: TRAINEE, applicationStatus: '', submitted: false }
        )
    } catch (error) {
        __DEV__ && console.log('error getting user: ', error)
        return 'TRAINEE'
    }
}

export async function changePassword({ oldPassword, newPassword }) {
    try {
        const user = await Auth?.currentAuthenticatedUser()
        try {
            const result = await Auth?.changePassword(user, oldPassword, newPassword)
            return result
        } catch (changePasswordError) {
            __DEV__ && console.log('changePasswordError : ', changePasswordError)
            return changePasswordError
        }
    } catch (error) {
        __DEV__ && console.log('error getting user: ', error)
    }
}

//------------------- AWS/Amplify Actions --------------------
export async function getS3Object(key) {
    const uri = await Storage.get(key)
    return uri
}

export async function pickFile({
    fileName,
    id,
    setUploading,
    setUploadError,
    bucketPath = 'trainerApplicationDocuments',
    updateForm,
    fieldName = '',
}) {
    const fileUploadOptions = {
        type: '*/*',
        copyToCacheDirectory: false,
    }
    try {
        const result = await DocumentPicker.getDocumentAsync(fileUploadOptions)
        setUploading(true)
        if (result.type === 'success') {
            if (bucketPath === 'ClassBanners') {
                if (result.mimeType !== 'image/jpeg' && result.mimeType !== 'image/png') {
                    setUploadError({
                        showError: true,
                        error: 'Invalid banner type, please upload a .jpeg or .png file',
                    })
                    return setUploading(false)
                }
                setUploadError({ showError: false, error: '' })
            }
            const fileSizeMB = convertBytesToMB(result?.size)
            if (fileSizeMB > 5.0) {
                setUploadError({ showError: true, error: 'Class banner file size must be less than 5MB' })
                return setUploading(false)
            } else {
                const fileType = result?.file.type
                const dataUri = result?.uri
                const bucketFilePath = `${bucketPath}/${id}`
                return await uploadFile({
                    bucketFilePath,
                    fileName,
                    fileType,
                    dataUri,
                    setUploading,
                    updateForm,
                    fieldName,
                })
            }
        } else {
            setUploadError({ showError: true, error: 'Error uploading class banner' })
            return setUploading(false)
        }
    } catch (error) {
        setUploading(false)
        __DEV__ && console.log('Error selecting file: ', error)
        return false
    }
}
export async function pickImage({ fileName, id, setUploading, bucketPath, updateForm, fieldName }) {
    let { cancelled, type, uri } = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        aspect: [4, 3],
        quality: 0.5,
    })

    if (!cancelled) {
        try {
            setUploading(true)
            const fileType = type
            const dataUri = uri
            const bucketFilePath = `${bucketPath}/${id}`
            return await uploadFile({
                bucketFilePath,
                fileName,
                fileType,
                dataUri,
                setUploading,
                updateForm,
                fieldName,
            })
        } catch (error) {
            __DEV__ && console.log('error uploading image: ', error)
            return false
        }
    } else {
        return false
    }
}
async function uploadFile({
    bucketFilePath,
    fileName,
    fileType,
    dataUri,
    setUploading,
    updateForm,
    fieldName,
    encrypted = false,
}) {
    try {
        const response = await fetch(dataUri)
        const blob = await response.blob()
        const standardOptions = { contentType: fileType }
        const encryptedOptions = {
            ...standardOptions,
            serverSideEncryption: 'AES256',
            SSECustomerAlgorithm: 'string',
            SSECustomerKey: new Buffer('...') || 'string',
            SSECustomerKeyMD5: 'string',
            SSEKMSKeyId: 'string',
        }
        const { key } = await Storage.put(`${bucketFilePath}/${fileName}`, blob, standardOptions)
        if (fieldName) {
            updateForm({ fieldName, nestedField: { bucket } })
            updateForm({ fieldName, nestedField: { region } })
            updateForm({ fieldName, nestedField: { key } })
        }
        setTimeout(() => {
            setUploading(false)
        }, 100)
        return key
    } catch (error) {
        setTimeout(() => {
            setUploading(false)
        }, 100)
        __DEV__ && console.log('Error uploading file: ', error)
        return ''
    }
}

export const deleteFile = ({ file, deleteFileMutation }) => {
    const { fileID, _version } = file
    deleteFileMutation({
        variables: {
            input: {
                id: fileID,
                _version,
            },
        },
    })
}

export const createFileObjects = async ({ uploads, fileType, trainerApplicationID, userID, createFileMutation }) => {
    uploads.forEach(async file => {
        const {
            name,
            file: { key },
            existing,
        } = file
        if (!existing && !!name) {
            createFileMutation({
                variables: {
                    input: {
                        name: file.name,
                        ownerName: '',
                        file: { bucket, region, key },
                        trainerApplicationID,
                        userID,
                        fileType,
                    },
                },
            })
        }
    })
}

export const updateTrainerApplication = ({
    updateTrainerApplicationMutation,
    createFileMutation,
    deleteFileMutation,
    trainerApplicationID,
    _version,
    userID,
    index,
    form,
    navigation,
    currentPage,
    isSubmit = false,
    isReviewing = false,
    isStartingOver = false,
    certificationUploads,
    businessRegistrationUploads,
}) => {
    if (currentPage === CERTIFICATIONS || currentPage === BUSINESS_REGISTRATION)
        createFileObjects({
            uploads: currentPage === CERTIFICATIONS ? certificationUploads : businessRegistrationUploads,
            fileType: currentPage === CERTIFICATIONS ? CERTIFICATION : BUSINESS,
            trainerApplicationID,
            userID,
            createFileMutation,
        })
    if (isStartingOver) {
        const certifications =
            getExistingFiles({ files: instructorApplicationForm()?.files?.items, fileType: 'CERTIFICATION' }) || []
        const businessUploads =
            getExistingFiles({ files: instructorApplicationForm()?.files?.items, fileType: 'BUSINESS' }) || []
        if (certifications.length) {
            certifications.forEach(file => {
                deleteFile({ file, deleteFileMutation })
            })
        }
        if (businessUploads.length) {
            businessUploads.forEach(file => {
                deleteFile({ file, deleteFileMutation })
            })
        }
    }
    const {
        firstName,
        lastName,
        username,
        isInfluencer,
        isOwner,
        isTrainer,
        location: locationWithType,
        socials: socialsWithType,
        gymStudioName,
        gymStudioAddress: gymStudioAddressWithType,
        specializations: specializationsWithType,
        website,
        approved,
    } = isStartingOver ? initialInstructorApplicationForm : form

    // we use ...rest to expand the rest of a nested object *WITHOUT* the typename bc graphQL gets mad about the typename
    const { __typename: locationTypeName, ...location } = locationWithType || emptyObj
    const { __typename: socialTypeName, ...socials } = socialsWithType || emptyObj
    const { __typename: specializationsTypeName, ...specializations } = specializationsWithType || emptyObj
    const { __typename: gymStudioAddressTypeName, ...gymStudioAddress } = gymStudioAddressWithType || emptyObj
    if (!isReviewing) {
        updateTrainerApplicationMutation({
            input: {
                id: trainerApplicationID,
                firstName,
                lastName,
                username,
                gymStudioName,
                isOwner,
                isInfluencer,
                isTrainer,
                submitted: isSubmit,
                location,
                socials,
                website,
                gymStudioAddress,
                userID,
                lastIndex: index,
                specializations,
                _version,
                approved,
            },
        })
    }
    if (isStartingOver) {
        instructorApplicationForm(initialInstructorApplicationForm)
        navigation.navigate(INSTRUCTOR_NAVIGATOR.START_TRAINER_APP)
    }
}

const { APPROVED, DENIED } = APPLICATION_STATUS

export async function reviewInstructorApplication({
    trainerApplicationID,
    updateTrainerApplicationMutation,
    updateUserMutation,
}) {
    const { approved, _version: instructorAppVersion } = instructorApplicationForm()
    updateTrainerApplicationMutation({
        input: {
            id: trainerApplicationID,
            _version: instructorAppVersion,
            approved,
            dateApproved: getTodaysDateInAWSFormat(),
        },
    })
    if (approved == APPROVED) {
        const { data } = await apolloClient.query({
            query: gql(getUser),
            variables: { id: trainerApplicationID },
        })
        const { _version, profile: profileWithType, email } = data?.getUser ?? {}
        const { __typename, ...profile } = profileWithType
        const {
            username,
            firstName,
            lastName,
            location: locationWithType,
            socials: socialsWithType,
            specializations: specializationsWithType,
        } = await getTrainerApplicationAsync({ id: trainerApplicationID })

        const { id: accountID = '' } = await stripeAPI({
            path: CREATE_STRIPE_CONNECT_ACCOUNT,
            body: { firstName, lastName, email },
        })

        // we use ...rest to expand the rest of a nested object *WITHOUT* the typename bc graphQL gets mad about the typename
        const { __typename: locationTypeName, ...location } = locationWithType || emptyObj
        const { __typename: socialTypeName, ...socials } = socialsWithType || emptyObj
        const { __typename: specializationsTypeName, ...specializations } = specializationsWithType || emptyObj

        updateUserMutation({
            input: {
                id: trainerApplicationID,
                _version,
                accountID,
                username,
                userType: USER_TYPES.INSTRUCTOR,
                userTypeString: USER_TYPES.INSTRUCTOR,
                classCategories: specializations,
                profile: {
                    firstName,
                    lastName,
                    location,
                    socials,
                },
            },
        })
    }
}

const getValidIntervals = ({ intervals }) => {
    //Match the shape of local intervals to shape defined in schema
    const validIntervals = intervals.map(interval => ({
        id: interval?.id,
        name: interval?.name,
        duration: interval?.duration,
        timeType: interval?.timeType,
        type: interval?.type,
    }))

    return validIntervals
}

export const updateClassStatus = async ({ updateClassMutation, id, _version, classStatus }) => {
    const { data: { updateClass: updatedClass } = emptyObj } = await updateClassMutation({
        input: {
            id,
            _version,
            classStatus,
            classStatusString: classStatus,
        },
    })

    return updatedClass
}

export const updateClass = async ({ updateClassMutation, intervals, currentPage }) => {
    const {
        id,
        userID,
        _version,
        name,
        description,
        startTime,
        date,
        categoryFilter,
        category: categoryWithType,
        duration,
        equipment,
        intervals: intervalsFromForm,
        banner: bannerWithType,
        classStatus,
        price: priceFromForm,
        playlist,
        defaultBannerIndex,
    } = createClassForm()

    const price = priceFromForm ? parseFloat(priceFromForm) : 0.0
    const isIntervals = currentPage === INTERVALS
    const validIntervals = getValidIntervals({ intervals: isIntervals ? intervals : intervalsFromForm })
    const { __typename: bannerTypeName, __persist: bannerPersist, ...banner } = bannerWithType || emptyObj
    const { __typename: categoryTypeName, __persist: categoryPersist, ...category } = categoryWithType || emptyObj

    const {
        data: {
            updateClass: { _version: updatedVersion },
        },
    } = await updateClassMutation({
        input: {
            id,
            userID,
            name,
            description,
            startTime,
            date,
            categoryFilter,
            category,
            duration,
            equipment,
            intervals: validIntervals,
            classStatus,
            classStatusString: classStatus,
            price,
            playlist,
            banner,
            defaultBannerIndex,
            _version,
        },
    })
    createClassForm({ ...createClassForm(), _version: updatedVersion })
}

export const createClass = async ({ userID, createClassMutation }) => {
    const {
        name,
        description,
        date,
        startTime,
        category,
        duration,
        equipment,
        banner,
        intervals,
        classStatus,
        playlist,
        defaultBannerIndex,
    } = createClassForm()

    const {
        data: {
            createClass: { id, _version },
        },
    } = await createClassMutation({
        input: {
            userID,
            name,
            description,
            date,
            startTime,
            category,
            duration,
            equipment,
            intervals,
            classStatus,
            classStatusString: classStatus,
            price: 0.0,
            playlist,
            banner,
            defaultBannerIndex,
        },
    })

    createClassForm({ ...createClassForm(), id, _version, userID })
}

export const updateUserAvatar = async ({ id, _version, updateUserMutation }) => {
    const { avatar } = userProfileForm()
    await updateUserMutation({
        input: {
            id,
            _version,
            avatar,
        },
    })
}

export const updateUserCustomerID = async ({ id, _version, updateUserMutation, customerID }) => {
    const result = await updateUserMutation({
        input: {
            id,
            _version,
            customerID,
        },
    })
    return result
}

export const updateUserAccountID = async ({ id, _version, updateUserMutation, accountID }) => {
    const result = await updateUserMutation({
        input: {
            id,
            _version,
            accountID,
        },
    })

    return result
}

export const updateUserPushToken = async ({ id, _version, pushToken, updateUserMutation }) => {
    await updateUserMutation({
        input: {
            id,
            _version,
            pushToken,
        },
    })
}

export const updateUserProfile = async ({ id, _version, updateUserMutation, isTraineeProfile = false }) => {
    const {
        firstName,
        lastName,
        bio,
        website,
        socials: socialsWithType,
        location: locationWithType,
        specializations: specializationsWithType,
        specializationFilter,
        username,
    } = userProfileForm()
    const { __typename: socialTypeName, ...socials } = socialsWithType || emptyObj
    const { __typename: locationTypeName, ...location } = locationWithType || emptyObj
    const { __typename: specializationTypeName, ...specializations } = specializationsWithType || emptyObj

    const input = !isTraineeProfile
        ? {
              id,
              _version,
              username,
              firstName,
              lastName,
              classCategories: specializations,
              categoryFilter: specializationFilter,
              profile: {
                  firstName,
                  lastName,
                  bio,
                  socials,
                  website,
                  location,
              },
          }
        : {
              id,
              _version,
              firstName,
              lastName,
              profile: {
                  firstName,
                  lastName,
              },
          }
    await updateUserMutation({ input })
}

export const createClassBooking = async ({ classID, userID, createClassBookingMutation }) => {
    await createClassBookingMutation({
        input: {
            classID,
            userID,
        },
    })
}

export const createReview = async ({ review, rating, userID, instructorID, classID, createReviewMutation }) => {
    return await createReviewMutation({
        input: {
            review,
            rating,
            userID,
            classID,
            instructorID,
        },
    })
}

export const updateStream = async ({ streamID, _version, isHostInStreamRoom, updateStreamMutation }) => {
    await updateStreamMutation({
        input: {
            id: streamID,
            _version,
            isHostInStreamRoom,
        },
    })
}

export const createStream = async ({ classID, createStreamMutation, instructorID }) => {
    return await createStreamMutation({
        input: {
            id: classID,
            classID,
            isHostInStreamRoom: false,
        },
    })
}

export const deleteClassBooking = async ({ classBooking, deleteClassBookingMutation }) => {
    const { id, _version } = classBooking || emptyObj
    return await deleteClassBookingMutation({
        input: {
            id,
            _version,
        },
    })
}

export const deleteClass = async ({ id, _version, deleteClassMutation }) => {
    return await deleteClassMutation({
        input: {
            id,
            _version,
        },
    })
}

export const deleteUser = async ({ id, _version, deleteUserMutation }) => {
    return await deleteUserMutation({
        input: {
            id,
            _version,
        },
    })
}

export const deleteStream = async ({ id, _version, deleteStreamMutation }) => {
    return await deleteStreamMutation({
        input: {
            id,
            _version,
        },
    })
}

export const createFavoriteInstructor = async ({ userID, instructorID, createFavoriteInstructorMutation }) => {
    return await createFavoriteInstructorMutation({
        input: {
            userID,
            instructorID,
        },
    })
}

export const deleteFavoriteInstructor = async ({ favoriteInstructor, deleteFavoriteInstructorMutation }) => {
    const { id, _version } = favoriteInstructor || emptyObj
    return await deleteFavoriteInstructorMutation({
        input: {
            id,
            _version,
        },
    })
}

//------------------- Push Notification Actions --------------------
export async function sendPushNotification({ content }) {
    try {
        await fetch('https://exp.host/--/api/v2/push/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(content),
        })
    } catch (error) {
        __DEV__ && console.log('error sending push notification : ', error)
    }
}

export async function sendMultiplePushNotifications({ users, title = '', body = '', data = emptyObj }) {
    const content = users?.map(user => ({
        to: user?.pushToken,
        title,
        body,
        data,
    }))

    try {
        await fetch('https://exp.host/--/api/v2/push/send', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(content),
        })
    } catch (error) {
        __DEV__ && console.log('error sending multiple push notifications : ', error)
    }
}

export async function schedulePushNotification({ content, secondsUntilNotification = 600 }) {
    try {
        await Notifications.scheduleNotificationAsync({
            content,
            trigger: {
                seconds: secondsUntilNotification,
            },
        })
    } catch (error) {
        __DEV__ && console.log('error scheduling push notification: ', error)
    }
}

export const createNotification = async ({
    userID,
    pushToken,
    content,
    type,
    createNotificationMutation,
    secondsUntilNotification,
    isSingleNotification = true,
    isScheduledNotification = false,
}) => {
    await createNotificationMutation({
        input: {
            userID,
            pushToken,
            content,
            type,
            isNew: true,
        },
    })

    if (pushToken && isSingleNotification) {
        const contentWithPushToken = { to: pushToken, ...content }

        if (isScheduledNotification) {
            await schedulePushNotification({ content: contentWithPushToken, secondsUntilNotification })
        } else {
            await sendPushNotification({ content: contentWithPushToken })
        }
    }
}

export const updateNotification = async ({ notification, updateNotificationMutation, isNew = true }) => {
    const { id, _version } = notification || emptyObj
    await updateNotificationMutation({
        input: {
            id,
            _version,
            isNew,
        },
    })
}
