import React, { useState, useEffect } from 'react'
import { TextInput, View, TouchableOpacity, FlatList } from 'react-native'
// ui
import { useDimensions } from 'dimensions'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { getPriceString, getTransactionDate } from 'helpers'
// components
import DropDownPicker from 'components/DropDownInput'
import { dateFilterPickerItems } from 'pickerItems'
import { RowContainer } from 'commons/containers'
import { Inter700, Caption } from 'styles/typography'
import VectorIcon from '../VectorIcon'
import IconCheckBox from 'commons/icons/IconCheckBox'
//Stripe
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
import { GraphQLNonNull } from 'graphql'
const { LIST_TRANSFERS } = STRIPE_API_ROUTES

const PayoutCard = ({ item, checkedItems, setCheckedItems }) => {
    const { id: currentID } = item
    const [isChecked, setIsChecked] = useState(false)
    const total = getPriceString((item?.amount / 4) * 5)
    const price = getPriceString(item?.amount)
    const date = getTransactionDate(item?.created)
    const method = item?.object === 'transfer' ? 'Stripe Account' : 'cheese'
    const type = item?.object === 'transfer' ? 'transfer' : 'cheese'

    const handleChecked = () => {
        setIsChecked(!isChecked)
        setCheckedItems(isChecked ? checkedItems?.filter(item => item !== currentID) : [...checkedItems, currentID])
    }

    useEffect(() => {
        setIsChecked(checkedItems.includes(currentID))
    }, [checkedItems])

    return (
        <PayoutContainer>
            <TableRow style={{ marginHorizontal: 20 }}>
                <IconCheckBox
                    isSquare
                    size={15}
                    color={{ unchecked: colors.black, checked: colors.black }}
                    checked={isChecked}
                    onPress={handleChecked}
                />
                <TableHeader width="68px">
                    <PayoutRowText>{total}</PayoutRowText>
                </TableHeader>
                <TableHeader width="188px">
                    <PayoutRowText>{item?.metadata?.className}</PayoutRowText>
                </TableHeader>
                <TableHeader>
                    <PayoutRowText>{method}</PayoutRowText>
                </TableHeader>
                <TableHeader width="80px">
                    <PayoutStatusContainer>
                        <PayoutRowText isSmall color={colors.white}>
                            Sent
                        </PayoutRowText>
                    </PayoutStatusContainer>
                </TableHeader>
                <TableHeader width="95px">
                    <PayoutRowText
                        color={colors.deactiveLightmode}
                    >{`${date?.month}/${date?.day}/${date?.year}`}</PayoutRowText>
                </TableHeader>
                <TableHeader width="60px">
                    <PayoutRowText>{price}</PayoutRowText>
                </TableHeader>
                <TableHeader width="80px">
                    <PayoutRowText>{type}</PayoutRowText>
                </TableHeader>
            </TableRow>
        </PayoutContainer>
    )
}

export default function PayoutHistory({ accountID }) {
    const { isMobile } = useDimensions()
    const [transfers, setTransfers] = useState([])
    const [searchText, setSearchText] = useState('')
    const [dateFilter, setDateFilter] = useState('All Classes')
    const [selectAll, setSelectAll] = useState(false)
    const [checkedItems, setCheckedItems] = useState([])

    const handleChecked = () => {
        setSelectAll(!selectAll)
        selectAll
            ? setCheckedItems([])
            : setCheckedItems(
                  transfers.reduce((acc, item) => {
                      return [...acc, item?.id]
                  }, []),
              )
    }
    const renderItem = ({ item }) => {
        return <PayoutCard item={item} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
    }

    useEffect(() => {
        async function listTransfers() {
            const transferResult = await stripeAPI({
                path: LIST_TRANSFERS,
                pathParameters: accountID,
            })
            __DEV__ && console.log('transfers: ', transferResult)
            setTransfers(transferResult)
        }

        accountID && listTransfers()
    }, [accountID])

    useEffect(() => {
        checkedItems.length < transfers.length && setSelectAll(false)
    }, [checkedItems])

    return (
        <PayoutHistoryContainer>
            <HeaderText>Class History</HeaderText>
            <TopRowContainer style={{ zIndex: 100 }}>
                <DropDownContainer>
                    <DropDownPicker
                        dropDownItems={dateFilterPickerItems}
                        selected={dateFilter}
                        fieldTitle=""
                        fieldName=""
                        shouldPushContent={false}
                        containerStyle={{ paddingLeft: 20 }}
                        onChangeValue={value => setDateFilter(value)}
                    />
                    <DropDownPicker
                        dropDownItems={dateFilterPickerItems}
                        selected={dateFilter}
                        fieldTitle=""
                        fieldName=""
                        shouldPushContent={false}
                        containerStyle={{ paddingLeft: 20 }}
                        onChangeValue={value => setDateFilter(value)}
                    />
                    <ClassSearchBarContainer>
                        <MagnifyingGlassContainer>
                            <VectorIcon
                                family="SimpleLineIcons"
                                name="magnifier"
                                size={isMobile ? 20 : 24}
                                color={colors.subTextGray}
                            />
                        </MagnifyingGlassContainer>
                        <SearchBar
                            selectionColor={colors.homebodyTurquoise}
                            placeholder={'Search'}
                            value={searchText}
                            onChangeText={setSearchText}
                            onSubmitEditing={() => {}}
                        />
                    </ClassSearchBarContainer>
                </DropDownContainer>
                <ButtonContainer>
                    <IconContainer>
                        <VectorIcon family="MaterialIcons" name="edit" size={20} color={colors.black} />
                    </IconContainer>
                    <IconContainer>
                        <VectorIcon family="MaterialIcons" name="delete-outline" size={20} color={colors.black} />
                    </IconContainer>
                </ButtonContainer>
            </TopRowContainer>
            <TableRow style={{ marginHorizontal: 20, marginTop: 30 }}>
                <IconCheckBox
                    isSquare
                    size={15}
                    color={{ unchecked: colors.black, checked: colors.black }}
                    checked={selectAll}
                    onPress={handleChecked}
                />
                <TableHeader width="68px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Transaction
                    </TableRowText>
                </TableHeader>
                <TableHeader width="188px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Class Name
                    </TableRowText>
                </TableHeader>
                <TableHeader width="90px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Payout Method
                    </TableRowText>
                </TableHeader>
                <TableHeader width="80px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Status
                    </TableRowText>
                </TableHeader>
                <TableHeader width="95px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Date Processed
                    </TableRowText>
                </TableHeader>
                <TableHeader width="60px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Payout
                    </TableRowText>
                </TableHeader>
                <TableHeader width="80px">
                    <TableRowText isSmall color={colors.deactiveLightmode}>
                        Type
                    </TableRowText>
                </TableHeader>
            </TableRow>
            <TransactionFlatList data={transfers} renderItem={renderItem} keyExtractor={item => `${item.id}`} />
        </PayoutHistoryContainer>
    )
}

const TransactionFlatList = styled(FlatList)`
    width: 100%;
    max-height: 400px;
    padding-bottom: 20px;
`
const PayoutHistoryContainer = styled(View)`
    flex: 1;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
const TopRowContainer = styled(RowContainer)`
    flex-direction: row;
    justify-content: space-between;
`
const DropDownContainer = styled(RowContainer)`
    width: 50%;
    align-items: center;
    justify-content: space-between;
    padding-top: 3%;
`
const ClassSearchBarContainer = styled(View)`
    flex-direction: row;
    width: 40%;
    align-items: center;
    padding-left: 20px;
`
const SearchBar = styled(TextInput)`
    flex: 1;
    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
    padding-left: ${({ theme }) => (theme.isMobile ? 45 : 50)}px;
    background-color: ${colors.searchBarGray};
    font-family: Inter_600SemiBold;
    color: ${colors.darkGrey};
    border-radius: 10px;
    overflow: hidden;
`
const ButtonContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-right: 3%;
`
const MagnifyingGlassContainer = styled(View)`
    position: absolute;
    z-index: 10;
    padding-left: 15px;
`
const IconContainer = styled(TouchableOpacity)`
    height: 40px;
    width: 40px;
    align-self: flex-end;
    justify-content: center;
    color: ${colors.white};
    border-radius: 40px;
    margin-right: 20%;
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
const HeaderText = styled(Inter700)`
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    margin-left: 20px;
    margin-top: 20px;
`
export const TableRow = styled(View)`
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    padding-vertical: 15px;
    border-bottom-width: 1px;
    border-bottom-color: ${colors.deactiveLightmode};
`
export const TableHeader = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    ${props => props.width && `width: ${props.width};`}
`
export const TableRowText = styled(Caption)``

const PayoutRowText = styled(TableRowText)``
const PayoutStatusContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 16px;
    background: ${colors.homebodyGreen};
    border-radius: 3px;
`
const PayoutContainer = styled(View)`
    flex: 1;
    width: 100%;
`
